import React, { useState, useEffect } from 'react';
import DialogTitle from "@mui/material/DialogTitle";
import {DialogActions, DialogContent, LinearProgress} from "@mui/material";
import Toast from 'hays/toast';
import LoadingButton from "@mui/lab/LoadingButton";
import CheckIcon from "@mui/icons-material/Check";
import './file-upload.component.scss';
import S3PresignedUrlGetInput from "modules/file-manager/inputs/s3-presigned-url-get.input";
import FileApi from "modules/file-manager/apis/file.api";

const MaxAllowedSize = 5 // 5MB

const AllowedTypes = {
    PNG: 'image/png',
    JPG: 'image/jpg',
    JPEG: 'image/jpeg',
    PDF: 'application/pdf',
    XLS: 'application/vnd.ms-excel',
    XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    TXT: 'text/plain',
    DOC: 'application/msword',
}

export { AllowedTypes as FileUploadAllowedTypes }

const AllowedFilesTypes = [
    AllowedTypes.PNG,
    AllowedTypes.JPG,
    AllowedTypes.JPEG,
    AllowedTypes.PDF,
    AllowedTypes.XLS,
    AllowedTypes.XLSX,
    AllowedTypes.TXT,
    AllowedTypes.DOC,
];

export { AllowedFilesTypes as AllFileUploadAllowedTypes }

const AllowedImagesTypes = [
    AllowedTypes.PNG,
    AllowedTypes.JPG,
    AllowedTypes.JPEG,
];
export { AllowedImagesTypes as AllFileUploadAllowedImagesTypes }


function FileUpload(props) {

    const {
        onChange = () => {},
        allowedTypes = [],
        onHide = () => {},
        folder = null,
        extra = null,
    } = props;

    const [file, fileSet] = useState(null);
    const [src, srcSet] = useState(null);
    const [loading, loadingSet] = useState(false);
    const [percentComplete, percentCompleteSet] = useState(0);


    const onUpload = () => {
        (async () => {

            try {

                loadingSet(true);
                const inputs = new S3PresignedUrlGetInput();
                inputs.file_mime = file.type;
                inputs.file_extension = file.name.split('.').pop();
                inputs.folder = folder;
                inputs.sub_folder = '';
                const result = await FileApi.S3PresignedUrl(inputs);

                const options = {
                    headers: {
                        'Content-Type': file.type
                    }
                };

                await FileApi.uploadS3(result.s3_upload_presigned_url, file, (_percentComplete) => {
                    percentCompleteSet(_percentComplete);
                });
                fileSet(null);
                loadingSet(false);
                Toast.success('File uploaded successfully');
                onChange({
                    data: result,
                    extra: extra
                });

                onHide();

            }
            catch (e) {
                loadingSet(false);
                percentCompleteSet(0);
                console.error(e);
            }
        })()

    };

    const onFileChange = (e) => {
        const _file = e.target.files[0];

        if (!_file) {
            return;
        }

        if (allowedTypes.indexOf(_file.type.toLowerCase()) === -1 ) {
            Toast.danger('Not allowed file type');
            return;
        }

        if (_file.size > (MaxAllowedSize * 1024 * 1024)) {
            Toast.danger('Not allowed file size, max is ' + MaxAllowedSize +'MB');
            return;
        }

        fileSet(_file);
    };

    useEffect(() => {
        if (file) {
            if (AllowedImagesTypes.indexOf(file.type.toLowerCase()) > -1) {
                const oFReader = new FileReader();
                oFReader.readAsDataURL(file);

                oFReader.onload = function (oFREvent) {
                    srcSet(oFREvent.target.result);
                };
            }
            else {
                srcSet(null);
            }
        }
        else {
            srcSet(null);
        }

    }, [file]);

    return (
        <div className="file-upload-component">
            <DialogTitle>Upload</DialogTitle>
            <DialogContent>
                <label htmlFor="file" className={"file-upload-label " + (!loading ? 'enabled': '')}>
                    {(!loading) && <span>Select File</span> }

                    <div className="clearfix"/>

                    { (!!src) &&  <img className="image-preview" src={src} alt="your image" /> }
                    {
                        (!src && !!file) &&
                        <div className="file-preview">
                            {file.name}
                            <br/>
                            <i className="las la-file-alt"/>
                        </div>
                    }

                </label>

                <input disabled={loading} className="file-upload-input" type="file" id="file" name="file" onChange={onFileChange}/>
                <div className="space-lg" />
                {

                    (loading) &&  <LinearProgress variant="determinate" value={percentComplete} />
                }

            </DialogContent>

            <DialogActions>
                <LoadingButton
                    variant="outlined"
                    loading={loading}
                    disabled={!file || loading}
                    loadingPosition="start"
                    startIcon={<CheckIcon />}
                    onClick={onUpload}>
                    Upload
                </LoadingButton>
            </DialogActions>
        </div>
    );

    return (
        <div className="file-upload-component">

            <DialogContent>

            </DialogContent>
            <DialogActions>

            </DialogActions>
        </div>
    );
}

export default FileUpload;
