//@flow
import HrcInput from "hays/types/Input";
export default class NotificationListInput extends HrcInput{

    //query
    page: number;
    limit: number;

    constructor() {
        super();
        this._query = [
            'page',
            'limit',
        ];
    }

}
