//@flow
import HrcInput from "hays/types/Input";
export default class AuthLoginInput extends HrcInput{

    //payload
    identifier: string;
    identifier_type: string;
    password: string;


    constructor() {
        super();
        this._payload = [
            'identifier',
            'identifier_type',
            'password',
        ];
    }

}
