import React, { useState, useEffect, forwardRef } from 'react';
import {InputLabel} from "@mui/material";
import Select from 'react-select'
import "./select.scss";



const UiSelect = (props) => {

    const {
        options = [],
        value = [],
        name,
        id = name,
        multiple = false,
        keyBy = 'key',
        labelBy = 'label',
        label = '',
        onChange = (event) => {  },
        onBlur = (event) => {  },
        onFocus = (event) => {  },
        changeAsEvent = true,
        isClearable = true,
        ...rest
    } = props;

    const createEvent = (type, extra = {}) => {

        const element = document.getElementById(id + '_select_hidden_input_id');
        const event = document.createEvent("HTMLEvents");

        if (extra.hasOwnProperty('value')) {
            if (!extra.value && multiple) {
                extra.value = [];
            }
            element.value = JSON.stringify(extra.value);
        }

        event.initEvent(type, true, true);
        element.dispatchEvent(event);

        return event;

    };

    const handleChange = (e) => {
        if (changeAsEvent) {
            const event = createEvent('change', { value: e });
            onChange(event, e);
        }
        else {
            onChange(e);
        }
    };


    const handleBlur = (evt) => {
        const event = createEvent('blur');
        onBlur(event);
    };

    const handleFocus = (evt) => {
        const event = createEvent('focus');
        onFocus(event);
    };

    return (
        <div className="select-component">

            <input type="hidden" stringified-value="true" name={ name } id={ id + '_select_hidden_input_id' } />
            <Select
                {...rest}
                onChange={handleChange}
                onBlur={handleBlur}
                className={"hrc-select"}
                classNamePrefix={"hrc-select"}
                onFocus={handleFocus}
                isMulti={multiple}
                options={options}
                getOptionLabel={(opn) => opn[labelBy]}
                getOptionValue={(opn) => opn[keyBy]}
                name={ name + '_name'}
                menuPosition={'fixed'}
                isClearable={isClearable}
                value={value}/>
            <InputLabel className="select-label" id={id + "-label"} shrink={true}>{label}</InputLabel>
        </div>
    );

};

export default UiSelect;
