import CityListInput from "modules/settings/inputs/city-list.input";
import CityApi from "modules/settings/apis/city.api";
import _ from "lodash";
import UserListInput from "modules/user/inputs/user-list.input";
import UserApi from "modules/user/apis/user.api";

class SettingsService {

    #cities: any;
    #admins: any;

    async adminsList () {
        try {
            if (this.#admins) {
                return this.#admins;
            }

            const inputs = new UserListInput();
            inputs.paged = false;
            inputs.admin = 'YES';

            const result = await UserApi.list(inputs);
            this.#admins = result.users;

            return this.#admins;
        }
        catch (e) {
            throw e;
        }
    }

    async citiesList () {

        try {
            if (this.#cities) {
                return this.#cities;
            }
            const inputs = new CityListInput();
            inputs.with_parent = true;
            const result = await CityApi.list(inputs);
            let _cities = result.cities.map((item) => {
                item.label  = item.name_ar;
                if (item.parent) {
                    item.label = item.parent.name_ar + ' - ' + item.name_ar;
                }
                return item;
            });
            this.#cities = _.sortBy(_cities, 'label');
            return this.#cities;
        }
        catch (e) {
            throw e;
        }
    }

}

const settingsService = new SettingsService();
export default settingsService;
