import React, {useEffect, useState} from 'react';
import UserApi from "modules/user/apis/user.api";
import UserListInput from "modules/user/inputs/user-list.input";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import {useQuery} from "hays/hooks/use-query";
import {useParams, useSearchParams} from "react-router-dom";
import {getUserSearchFilters} from "modules/user/utils/user-filters.utils";
import UserFilters from "modules/user/components/user-filters/user-filters.component";
import "./users.container.scss";
import {Button, LinearProgress, Link} from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';
import _ from "lodash";
import UserEditModal, { UserEditModalApi } from "modules/user/modals/user-edit/user-edit.modal";


const getRequestId = () => {
    return  _.uniqueId('user-request-');
}
let lastRequestId = getRequestId();

function UsersContainer() {

    const query = useQuery(getUserSearchFilters());
    let [searchParams, setSearchParams] = useSearchParams();

    const [users, usersSet] = useState([]);
    const [loading, loadingSet] = useState(false);
    const [page, pageSet] = useState(1);
    const [lastPage, lastPageSet] = useState(0);
    const [filtersValues, filtersValuesSet] = useState({});

    const usersContent = users.map((item) => {
        return (
            <TableRow
                key={item.user_id}
                hover
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell >
                    <RouterLink to={"/tickets?user_id=" + item.user_id}>Tickets</RouterLink>
                </TableCell>
                <TableCell component="th" scope="row" className="cursor-pointer" onClick={() => onUserClick(item)}>
                    {item.name}
                </TableCell>
                <TableCell >{item.email}</TableCell>
                <TableCell >{item.mobile}</TableCell>
                <TableCell >{item.gender}</TableCell>
                <TableCell >{!!item.city.parent ? item.city.parent.name_ar : ''} - {item.city.name_ar}</TableCell>
                <TableCell >{item.date_of_birth}</TableCell>
                <TableCell >{item.status? 'Active' : 'Inactive'}</TableCell>
                <TableCell >{item.admin? 'Yes' : 'No'}</TableCell>
            </TableRow>
        );
    });

    const onPageChange = (event, page) => {
        setSearchParams({
            ...query,
            page: page
        });
    };

    const listUsers = (_query) => {
        (async () => {
            try {
                loadingSet(true);
                const requestId = getRequestId();
                lastRequestId = requestId;
                const inputs = new UserListInput();
                inputs.page = _query.page;
                inputs.name = _query.name;
                inputs.email = _query.email;
                inputs.mobile = _query.mobile;
                inputs.gender = _query.gender;
                inputs.admin = _query.admin;
                inputs.active = _query.active;
                inputs.limit = 10;
                if (_query.city) {
                    inputs.cities_ids = _query.city.split(',');
                }
                const result = await UserApi.list(inputs);
                if (requestId === lastRequestId) {
                    usersSet(result?.users?.data || []);
                    lastPageSet(result.users.last_page === 1 ? 0 : result.users.last_page);
                    loadingSet(false);
                }

            } catch (e) {
                console.error(e);
                loadingSet(false);
            }
        })();
    }

    const onUserClick = (item) => {
        UserEditModalApi.show(true, {
            user: item
        });
    };


    const onUserFiltersChange = (payload) => {
        delete payload.raw_values.page;
        setSearchParams(payload.raw_values);
    };

    const onUserEditModalChange = () => {
        listUsers(query);
    }

    useEffect(() => {
        pageSet(query.page? parseInt(query.page) : 1);
        filtersValuesSet(query);
        if (!query.first_change) {
            listUsers(query);
        }
    }, [query]);

    return (
        <div className="users-container">
            <UserEditModal onChange={onUserEditModalChange}/>
            <div className="users-header">
                <UserFilters values={filtersValues} onChange={onUserFiltersChange}/>
                {
                    (lastPage > 0) &&
                    <div style={{minWidth: '350px', justifyContent: 'flex-end', display: 'flex'}}>
                        <Pagination count={lastPage} page={page} onChange={onPageChange} />
                    </div>
                }

            </div>

            <div className="space-lg"/>
            {
                (loading) &&
                <>
                    <div className="space-lg"/>
                    <div className="space-lg"/>
                    <LinearProgress />

                </>
            }

            {
                (!loading) &&
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell >Email</TableCell>
                                <TableCell >Mobile</TableCell>
                                <TableCell >Gender</TableCell>
                                <TableCell >City</TableCell>
                                <TableCell >DOB</TableCell>
                                <TableCell >Status</TableCell>
                                <TableCell >Admin</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {usersContent}
                        </TableBody>
                    </Table>
                </TableContainer>
            }

        </div>
    );
}

export default UsersContainer;
