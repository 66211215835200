import http from "hays/http";
import WidgetUpdateInput from "modules/settings/inputs/widget-update.input";
const WidgetApi = {};

WidgetApi.list = async (inputs: any) => {
    return http.get('/settings/widgets')
};

WidgetApi.update = async (inputs: WidgetUpdateInput) => {
    return http.put('/settings/widgets', inputs.payload())
};

export default WidgetApi;
