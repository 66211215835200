import React, {useEffect, useState} from 'react';
import ContentApi from "modules/content/apis/content.api";
import ContentListInput from "modules/content/inputs/content-list.input";
import {SelectField, useForm} from "hays/form";
import {DialogContent, DialogTitle, LinearProgress} from "@mui/material";
import "./contents.container.scss";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckIcon from "@mui/icons-material/Check";
import ContentUpdateInput from "modules/content/inputs/content-update.input";
import Dialog from "@mui/material/Dialog";
import ContactEdit from "modules/contact/components/contact-edit/contact-edit.component";
import ContentVersionListInput from "modules/content/inputs/content-version-list.input";
import _ from "lodash";

const schema = {
};


const ACTIONS = {
    PREVIEW: 'PREVIEW',
    EDIT: 'EDIT',
};

const Actions = {
    [ACTIONS.PREVIEW]: {
        key: ACTIONS.PREVIEW,
        label: 'Preview'
    },
    [ACTIONS.EDIT]: {
        key: ACTIONS.EDIT,
        label: 'Edit'
    },
};

const ActionOptions = [
    Actions[ACTIONS.PREVIEW],
    Actions[ACTIONS.EDIT],
];

const initialValues = {
    content: null,
    action: Actions[ACTIONS.PREVIEW]
};

function ContentsContainer() {

    const [contents, contentsSet] = useState([]);
    const [content, contentSet] = useState(null);
    const [loading, loadingSet] = useState(false);
    const [version, versionSet] = useState('');
    const [valueAr, valueArSet] = useState('');
    const [valueEn, valueEnSet] = useState('');
    const [modalShow, setModalShow] = useState(false);

    const form = useForm({initialValues, schema});

    const contentList = () => {
        (async () => {
            try {
                loadingSet(true);
                const inputs = new ContentListInput();
                const result = await ContentApi.list(inputs);
                contentsSet(result.contents);
            } catch (e) {
                console.error(e);
            }
            finally {
                loadingSet(false);
            }
        })();
    };

    const onSubmitSuccess = ({values}) => {
        if (content.use_version) {
            setModalShow(true);
        }
        else {
            save(values);
        }

    }

    const save = (params) => {
        (async () => {
            try {
                setModalShow(false);
                loadingSet(true);
                const inputs = new ContentUpdateInput();
                inputs.key = content.key;
                inputs.content_ar = valueAr;
                inputs.content_en = valueEn;
                inputs.major_change = !!params?.major_change;
                const result = await ContentApi.update(inputs);
                listContentData();
            } catch (e) {
                console.error(e);
                loadingSet(false);
            }
        })();
    };

    const handleChange = (lang, event) => {
        if (lang === 'AR') {
            valueArSet(event.target.value);
        }
        else if (lang === 'EN') {
            valueEnSet(event.target.value);
        }
    }

    useEffect(() => {
        contentList();
    }, []);

    useEffect(() => {
        contentSet(form.values.content);
    }, [form.values]);

    const listContentData = () => {
        (async () => {
            try {
                loadingSet(true);
                const inputs = new ContentVersionListInput();
                inputs.keys = [content.key];
                const result = await ContentApi.versionList(inputs);
                const contentsVersions = _.keyBy(result?.contents_versions || [], 'language');
                form.api.setValue('action', Actions[ACTIONS.PREVIEW]);
                if (contentsVersions['AR'] && contentsVersions['EN']) {
                    const ar = contentsVersions['AR'];
                    versionSet(ar.version_major + '.' + ar.version_minor);
                    valueArSet(contentsVersions['AR'].content);
                    valueEnSet(contentsVersions['EN'].content);
                }

            } catch (e) {
                console.error(e);
            }
            finally {
                loadingSet(false);
            }
        })();
    }

    useEffect(() => {
        if (content) {
            valueArSet('');
            valueEnSet('');
            versionSet('');
            listContentData();
        }
    }, [content]);

    return (
        <div className="contents-container">

            <Dialog fullWidth={true} maxWidth={'sm'} open={modalShow} onClose={() => setModalShow(false)} className="hays-md-modal" >
                <DialogTitle>
                    Select Type
                </DialogTitle>
                <DialogContent>
                    <div className="alert alert-danger" role="alert">
                        Saving as new Major version will force exists users whose agreed on previous versions to re agree to this new version.
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <LoadingButton
                                fullWidth
                                size="large"
                                variant="contained"
                                color="error"
                                onClick={() => save({ major_change: true })}>
                                major
                            </LoadingButton>
                        </div>
                        <div className="col-9">
                            <LoadingButton
                                fullWidth
                                size="large"
                                variant="contained"
                                onClick={() => save({ major_change: false })}>
                                minor
                            </LoadingButton>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
            <div className="row">
                <div className="col-6">
                    <SelectField
                        placeholder={'All'}
                        options={contents}
                        isLoading={loading}
                        isDisabled={loading}
                        form={form}
                        isClearable={false}
                        name={'content'}
                        label={'Content'}
                    />
                </div>
                <div className="col-2">
                    <SelectField
                        placeholder={'All'}
                        options={ActionOptions}
                        isLoading={loading}
                        isDisabled={loading || !content}
                        form={form}
                        isClearable={false}
                        name={'action'}
                        label={'Action'}
                    />
                </div>
                <div className="col-2 d-flex align-items-center">
                    {
                        (!!form.dirty && content && form?.values?.action?.key === ACTIONS.EDIT) &&
                        <LoadingButton
                            fullWidth
                            size="large"
                            variant="outlined"
                            loading={loading}
                            loadingPosition="start"
                            startIcon={<CheckIcon />}
                            onClick={form.handleSubmit(onSubmitSuccess)}>
                            Save
                        </LoadingButton>
                    }

                </div>
            </div>

            <div className="space-lg"/>
            {
                (loading) &&
                <>
                    <div className="space-lg"/>
                    <div className="space-lg"/>
                    <LinearProgress />

                </>
            }

            {
                (!loading && content) &&
                <div className="d-flex">
                    <div className="me-4">
                        <div className="mb-1">Arabic ({version})</div>
                        <div className="mobile-screen">

                            {
                                (form?.values?.action?.key === ACTIONS.PREVIEW) &&
                                <div className="content-view">
                                    <div dangerouslySetInnerHTML={{ __html: valueAr }} />
                                </div>
                            }
                            {
                                (form?.values?.action?.key === ACTIONS.EDIT) &&
                                <textarea value={valueAr} onChange={(event) => handleChange('AR', event)} />
                            }

                        </div>
                    </div>
                    <div>
                        <div className="mb-1">English ({version})</div>
                        <div className="mobile-screen">
                            {
                                (form?.values?.action?.key === ACTIONS.PREVIEW) &&
                                <div className="content-view">
                                    <div dangerouslySetInnerHTML={{ __html: valueEn }} />
                                </div>
                            }
                            {
                                (form?.values?.action?.key === ACTIONS.EDIT) &&
                                <textarea value={valueEn} onChange={(event) => handleChange('EN', event)} />
                            }

                        </div>
                    </div>

                </div>
            }

        </div>
    );
}

export default ContentsContainer;
