//@flow
import HrcInput from "hays/types/Input";
export default class ContentUpdateInput extends HrcInput{


    //payload
    key: number;
    major_change: boolean;
    content_ar: string;
    content_en: string;


    constructor() {
        super();
        this._payload = [
            'key',
            'major_change',
            'content_ar',
            'content_en',
        ];
    }

}
