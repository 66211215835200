import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {DatePickerField, SelectField, useForm} from "hays/form";
import {contactStatus, getContactStatus} from "modules/contact/utils/contact.utils";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckIcon from "@mui/icons-material/Check";
import settingsService from "modules/settings/others/settings.service";
import ContactUpdateInput from "modules/contact/inputs/contact-update.input";
import ContactApi from "modules/contact/apis/contact.api";

const schema = {
};

const initialValues = {
    status: null,
    follow_by: null,
};

const ContactStatus = getContactStatus();

function ContactEdit(props) {

    const {
        contact = null,
        onChange = () => {},
    } = props;

    const form = useForm({initialValues, schema});

    const [loading, loadingSet] = useState(false);
    const [admins, adminsSet] = useState([]);

    const onSubmitSuccess = ({values}) => {
        (async () => {
            try {

                const inputs = new ContactUpdateInput();
                inputs.contact_id = contact.contact_id;
                inputs.status = values.status.key;
                inputs.followed_id = values.follow_by ? values.follow_by.user_id : null;

                const result = await ContactApi.update(inputs);
                form.api.setFormDirty(false);
                onChange();

            } catch (e) {
                console.error(e);
            }
        })();
    }

    const listAdmins = () => {
        (async () => {
            try {
                loadingSet(true);
                const admins = await settingsService.adminsList();
                adminsSet(admins);
                loadingSet(false);

            } catch (e) {
                console.error(e);
                loadingSet(false);
            }
        })();
    }


    useEffect(() => {
        if (contact) {
            form.api.setValue('status', contactStatus[contact.status]);
            form.api.setValue('follow_by', contact.follow_by);
        }

    }, [contact]);

    useEffect(() => {
        listAdmins();
    }, []);



    if (!contact) {
        return <></>
    }

    return (
        <div className="contact-edit-component">
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        InputProps={{ readOnly: true, }}
                        label="User"
                        value={contact.user.name}
                        InputLabelProps={{ shrink: true}}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        InputProps={{ readOnly: true, }}
                        label="Date"
                        value={contact.created_at_formatted}
                        InputLabelProps={{ shrink: true}}
                    />
                </Grid>
            </Grid>
            <div className="space-lg mb-2" />
            <div>
                <TextField
                    fullWidth
                    multiline={true}
                    InputProps={{ readOnly: true, }}
                    label="Message"
                    value={contact.message}
                    InputLabelProps={{ shrink: true}}
                />
            </div>
            <div className="space-lg mb-2" />
            <div className="row">
                <div className="col">
                    <SelectField
                        isClearable={false}
                        placeholder={'All'}
                        options={ContactStatus}
                        form={form}
                        name={'status'}
                        label={'Status'}
                    />
                </div>
                <div className="col">
                    <SelectField
                        isClearable={false}
                        placeholder={'All'}
                        options={admins}
                        form={form}
                        name={'follow_by'}
                        isLoading={loading}
                        isDisabled={loading}
                        label={'Follow By'}
                        keyBy={'user_id'}
                        labelBy={'name'}
                    />
                </div>


            </div>
            <div className="space-lg mb-2" />
            <div className="d-flex justify-content-end">
                <LoadingButton
                    variant="outlined"
                    loading={loading}
                    disabled={!form.dirty}
                    loadingPosition="start"
                    startIcon={<CheckIcon />}
                    onClick={form.handleSubmit(onSubmitSuccess)}>
                    Save
                </LoadingButton>
            </div>
        </div>
    );
}

export default ContactEdit;
