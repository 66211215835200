import React, { useState } from 'react';

function Page404Container() {

    const [count, setCount] = useState(0);

    return (
        <div className="page-404-container">
            page-404-container
        </div>
    );
}

export default Page404Container;
