import React, {useEffect, useState} from 'react';
import './user-filters.component.scss';
import UserFiltersModal, {UserFiltersModalApi} from "modules/user/modals/user-filters/user-filters.modal";
import { AccessAlarm, ThreeDRotation } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import Chip from "@mui/material/Chip";
import _ from "lodash";

function UserFilters(props) {

    const {
        values = {},
        onChange = () => {},
    } = props;

    const [localValues, localValuesSet] = useState({});


    useEffect(() => {

        const {
            city,
            ...other
        } = values;

        other.city = city ? city.split(',') : [];

        localValuesSet(other);
    }, [values]);

    const openModal = () => {
        UserFiltersModalApi.show(true, {
            values: localValues
        });
    };

    const onUserFiltersModalChange = (payload) => {
        UserFiltersModalApi.show(false);
        const raw_values = payload.raw_values;
        if (raw_values.city && raw_values.city.length) {
            raw_values.city = raw_values.city.join(',');
        }
        onChange({
            raw_values: raw_values,
        });

    };

    const handleDelete = (key) => {
        const _localValues = _.cloneDeep(localValues)
        delete _localValues[key];
        const raw_values = _localValues
        if (raw_values.city && raw_values.city.length) {
            raw_values.city = raw_values.city.join(',');
        }
        onChange({
            raw_values: raw_values,
        });

    };

    return (
        <div className="user-filters-component">
            <UserFiltersModal onChange={onUserFiltersModalChange}/>

            <div className="d-flex align-items-center flex-wrap">
                <IconButton aria-label="delete" color="primary" onClick={openModal}>
                    <PersonSearchIcon />
                </IconButton>

                {
                    (localValues && !!localValues.name && localValues.name !== '') &&
                    <Chip label={
                        <>
                            <span className="me-1">Name</span>
                            <b>{localValues.name}</b>
                        </>
                    } onDelete={() => handleDelete('name')} className="me-1  mb-1" />
                }

                {
                    (localValues && !!localValues.email && localValues.email !== '') &&
                    <Chip label={
                        <>
                            <span className="me-1">Email</span>
                            <b>{localValues.email}</b>
                        </>
                    } onDelete={() => handleDelete('email')} className="me-1  mb-1" />
                }

                {
                    (localValues && !!localValues.mobile && localValues.mobile !== '') &&
                    <Chip label={
                        <>
                            <span className="me-1">Mobile</span>
                            <b>{localValues.mobile}</b>
                        </>
                    } onDelete={() => handleDelete('mobile')} className="me-1 mb-1" />
                }

                {
                    (localValues && !!localValues.gender && localValues.gender !== '') &&
                    <Chip label={
                        <>
                            <span className="me-1">Gender</span>
                            <b>{localValues.gender}</b>
                        </>
                    } onDelete={() => handleDelete('gender')} className="me-1 mb-1" />
                }

                {
                    (localValues && !!localValues.admin && localValues.admin !== '') &&
                    <Chip label={
                        <>
                            <span className="me-1">Admin</span>
                            <b>{localValues.admin}</b>
                        </>
                    } onDelete={() => handleDelete('admin')} className="me-1 mb-1" />
                }

                {
                    (localValues && !!localValues.active && localValues.active !== '') &&
                    <Chip label={
                        <>
                            <span className="me-1">Active Users</span>
                            <b>{localValues.active}</b>
                        </>
                    } onDelete={() => handleDelete('active')} className="me-1 mb-1" />
                }

                {
                    (localValues && !!localValues.city && localValues.city.length > 0) &&
                    <Chip label={
                        <>
                            <span className="me-1">City</span>
                            <b>{localValues.city.length} Selected</b>
                        </>
                    } onDelete={() => handleDelete('city')} className="me-1 mb-1" />
                }


            </div>


        </div>
    );
}

export default UserFilters;
