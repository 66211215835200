import React, { useState } from 'react';
import Header from "modules/dashboard/components/header/header.component";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Sidebar from "modules/dashboard/components/sidebar/sidebar.component";
import "./dashboard.container.scss";
import DashboardRouterContainer from "modules/dashboard/containers/dashboard-router/dashboard-router.container";

const drawerWidth = 240;

function DashboardContainer() {

    const [count, setCount] = useState(0);

    return (
        <div className="dashboard-container">
            <Box sx={{ display: 'flex' }}>
                <Header />
                <Sidebar />
                <Box component="main" sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
                >
                    <Toolbar />
                    <DashboardRouterContainer />
                </Box>
            </Box>

        </div>
    );
}

export default DashboardContainer;
