import React, { useState, useEffect, forwardRef } from 'react';

import {default as MuiTextField} from "@mui/material/TextField";
import { getError } from "./errors";


const TextField = (props, ref) => {

    const {
        form,
        name,
        onChange = (event) => {  },
        onBlur = (event) => {  },
        onFocus = (event) => {  },
        ...rest
    } = props;

    const handleChange = (event) => {
        form.handleChange(name, event.target.value);
        onChange(event);
    };

    const handleBlur = (event) => {
        onBlur(event);
        form.handleBlur(name);
    };

    const handleFocus = (event) => {
        onFocus(event);
        form.handleFocus(name);
    };

    const error = getError(name, form.errors);

    useEffect(() => {
        if (props.hasOwnProperty('value')) {
            throw "HrcInput didn't accept value prop directly, pass the value by form api";
        }
    }, [props.value]);

    useEffect(() => {
        if (!props.hasOwnProperty('name')) { throw "HrcInput: name required for useForm"; }
    }, [props.name]);



    return (
        <MuiTextField
            InputLabelProps={{ shrink: true}}
            value={form.values[name]}
            helperText={error}
            error={!!error}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onChange={handleChange}
            {...rest}
        />
    );


};

export default forwardRef(TextField);
