import React, { useState, useEffect } from 'react';
import CityAddModal, { CityAddModalApi } from "modules/dashboard/modals/city-add/city-add.modal";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import {CityAddFormMode} from "modules/dashboard/forms/city-add/city-add.form";
import CityApi from "modules/settings/apis/city.api";
import _ from "lodash";
import './cities-update.component.scss';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CityListInput from "modules/settings/inputs/city-list.input";

const getTree = (data, parentId) => {

    const sub = data.filter((item) => {
        return item.parent_city_id === parentId;
    });

    sub.forEach((item) => {
        item.children = getTree(data, item.city_id);
    });

    return sub;

};

const addToFlat = (tree, flat) => {
    tree.forEach((item) => {
        flat.push(item);
        addToFlat(item.children, flat);
        delete item.children;
    });
};

function CitiesUpdate() {

    const [loading, loadingSet] = useState(false);
    const [cities, citiesSet] = useState([]);

    const addCity = (parent) => {
        CityAddModalApi.show(true, {
            mode: CityAddFormMode.ADD,
            parent: parent
        })
    };

    const updateCity = (city) => {
        const parent = cities.find((item) => {
            return item.city_id === city.parent_city_id
        });

        CityAddModalApi.show(true, {
            mode: CityAddFormMode.UPDATE,
            parent: parent,
            city: city,
        })
    };

    const listCities = () => {
        (async () => {
            try {
                loadingSet(true);
                const inputs = new CityListInput();
                const result = await CityApi.list(inputs);

                let flat = [];
                const citiesTree = getTree(_.orderBy(result.cities, 'name_en'), null);
                addToFlat(citiesTree, flat);
                citiesSet(flat);

            }
            catch (e) {
                console.error(e);
            }
            finally {
                loadingSet(false);
            }
        })();
    };

    const citiesContent = cities.map((item) => {

        const paddingLeft = ((item.level - 1) * 36) + 'px';

       return (
           <div style={{paddingLeft: paddingLeft}} className="city-item-view" key={item.city_id}>
               <div className={'city-item level-' + item.level}>
                   <div onClick={() => updateCity(item)} className="city-name">
                       {item.name_en},  {item.name_ar}
                   </div>
                   <div className="level-label">
                       Level {item.level}
                   </div>
                   {
                       (item.level < 3) &&
                       <>
                           <IconButton size="small" className="btn"  aria-label="settings" onClick={() => addCity(item)}>
                               <AddIcon fontSize="inherit" />
                           </IconButton>
                       </>
                   }

               </div>

           </div>
       );
    });

    const onCityAddModalChange = () => {
        CityAddModalApi.show(false);
        listCities();
    };


    useEffect(() => {
        listCities();
    }, []);

    return (
        <div className="cities-update-component">

            <CityAddModal onChange={onCityAddModalChange}/>
            <Card>
                <CardHeader
                    title="Cities"
                    action={
                        <IconButton aria-label="settings" onClick={() => addCity(null)}>
                            <AddIcon />
                        </IconButton>
                    }
                />
                <CardContent>
                    { (loading) && <span>Loading</span> }
                    {citiesContent}
                </CardContent>
            </Card>

        </div>
    );
}

export default CitiesUpdate;
