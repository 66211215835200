import React, {useEffect, useState} from 'react';
import "./root.container.scss";
import DashboardContainer from "modules/dashboard/containers/dashboard/dashboard.container";
import { BrowserRouter } from "react-router-dom";
import ThemeProviderContainer from "modules/root/containers/theme-provider/theme-provider.container";
import Joi from "hays/joi";
import {setHrcFormConfig} from "hays/form";
import ReactNotification from 'react-notifications-component';
import {setEnvClient} from "hays/http";

import 'animate.css/animate.min.css';
import 'react-notifications-component/dist/theme.css';
import 'hays/toast/toast.scss';
import LoginContainer from "modules/auth/containers/login/login.container";



setHrcFormConfig({
    joi: Joi
});

setEnvClient({
    url: 'https://api.mashreqapp.devworld.info/api'
});

function RootContainer() {

    const [isAuthenticated, isAuthenticatedSet]  = useState(false)

    return (
        <div className="root-container">
            <ReactNotification />
            <BrowserRouter>
                <ThemeProviderContainer>
                    { (!isAuthenticated) &&<LoginContainer onSuccess={() => isAuthenticatedSet(true)} />}
                    { (isAuthenticated) && <DashboardContainer />}

                </ThemeProviderContainer>
            </BrowserRouter>

        </div>
    );
}

export default RootContainer;
