
import React, {useEffect, useState} from 'react';
import Dialog from "@mui/material/Dialog";
import UserFiltersForm from "modules/user/components/user-filters-form/user-filters-form.component";
import {DialogContent, DialogTitle} from "@mui/material";

const UserFiltersModalApi = {
    show: () => {},
}
export { UserFiltersModalApi as UserFiltersModalApi };

function UserFiltersModal(props) {

    const {
        onChange = () => {},
        ...rest
    }  = props;

    const [modalShow, setModalShow] = useState(false);
    const [values, valuesSet] = useState({});

    const show = (show = true, showOptions = {}) => {
        if (!!showOptions.values) {
            valuesSet(showOptions.values);
        }

        setModalShow(show);
    };

    useEffect(() => {
        UserFiltersModalApi.show = show;
    }, []);

    return (
        <Dialog open={modalShow} onClose={() => setModalShow(false)} className="hays-md-modal"  {...rest} >

            {
                (!!modalShow) &&
                <>
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        Search
                    </DialogTitle>
                    <DialogContent>
                        <div className="space-md"/>
                        <UserFiltersForm onChange={onChange} data={values} />
                    </DialogContent>
                </>
            }


        </Dialog>
    );

}

export default UserFiltersModal;
