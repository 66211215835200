import Http from "hays/http";
import AuthLoginInput from "modules/auth/inputs/auth-login.input";
const AuthApi = {};

AuthApi.login = async (inputs: AuthLoginInput) => {
    return Http.post('/auth/login', inputs.payload())
};


AuthApi.me = async () => {
    return Http.get('/users/me');
};

export default AuthApi;
