//@flow
import HrcInput from "hays/types/Input";
export default class ContentVersionListInput extends HrcInput{

    //query
    keys: string[];
    languages: string[]

    constructor() {
        super();
        this._query = [
            'keys',
            'languages',
        ];
    }

}
