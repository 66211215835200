import React, { useState, useEffect } from 'react';
import Dialog from "@mui/material/Dialog";

import CityAddForm from "modules/dashboard/forms/city-add/city-add.form";

const CityAddModalApi = {};
export {CityAddModalApi as CityAddModalApi};

function CityAddModal(props) {

    const {
        onChange = () => {},
        ...rest
    }  = props;

    const [modalShow, setModalShow] = useState(false);
    const [mode, modeSet] = useState(null);
    const [parent, parentSet] = useState(null);
    const [city, citySet] = useState(null);

    const show = (_show = true, showOptions = {}) => {
        setModalShow(_show);
        if (_show) {
            modeSet(showOptions.mode);
            parentSet(showOptions.parent);
            citySet(showOptions.city);
        }
    };

    useEffect(() => {
        CityAddModalApi.show = show;
    }, []);

    return (
        <Dialog open={modalShow} onClose={() => setModalShow(false)} className="hays-md-modal"  {...rest} >
            {
                (!!modalShow) && <CityAddForm onChange={onChange} city={city} mode={mode} parent={parent} />
            }
        </Dialog>
    );
}

export default CityAddModal;
