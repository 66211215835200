//@flow
import HrcInput from "hays/types/Input";
export default class NotificationSaveInput extends HrcInput{


    //payload
    notification_id: number;
    title_ar: string;
    title_en: string;
    body_ar: string;
    body_en: string;
    link_ar: string;
    link_en: string;
    image_id_ar: number;
    image_id_en: number;

    constructor() {
        super();
        this._payload = [
            'notification_id',
            'title_ar',
            'title_en',
            'body_ar',
            'body_en',
            'link_ar',
            'link_en',
            'image_id_ar',
            'image_id_en',
        ];
    }

}
