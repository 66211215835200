import React, {useEffect, useState} from 'react';
import {FormTextField, SelectField, useForm} from "hays/form";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckIcon from "@mui/icons-material/Check";
import _ from "lodash";
import settingsService from "modules/settings/others/settings.service";
import SearchIcon from '@mui/icons-material/Search';

const Gender = {
    MALE: {
        key: 'MALE',
        label: 'MALE',
    },
    FEMALE: {
        key: 'FEMALE',
        label: 'FEMALE',
    },
};

const Genders = [
    Gender.MALE,
    Gender.FEMALE,
];

const BooleanOption = {
    YES: {
        key: 'YES',
        label: 'YES',
    },
    NO: {
        key: 'NO',
        label: 'NO',
    },
}

const BooleanOptions = [
    BooleanOption.YES,
    BooleanOption.NO,
]

const schema = {
};

const initialValues = {
    name: '',
    mobile: '',
    email: '',
    city: [],
    gender: null,
    admin: null,
    active: null
};

function UserFiltersForm(props) {

    const {
        onChange = () => {},
        data = {}
    } = props;

    const form = useForm({initialValues, schema});
    const [loading, loadingSet] = useState(false);
    const [cities, citiesSet] = useState([]);

    const onSubmitSuccess = ({values}) => {

        const raw = _.cloneDeep(values);

        if (values && values.city && values.city.length) {
            raw.city = values.city.map((item) => item.city_id);
        }
        else {
            delete raw.city;
        }

        if (values.gender) {
            raw.gender = values.gender.key;
        }
        else {
            delete raw.gender;
        }

        if (values.admin) {
            raw.admin = values.admin.key;
        }
        else {
            delete raw.admin;
        }

        if (values.active) {
            raw.active = values.active.key;
        }
        else {
            delete raw.active;
        }

        if (values.name === '') {
            delete raw.name;
        }

        if (values.email === '') {
            delete raw.email;
        }

        if (values.mobile === '') {
            delete raw.mobile;
        }

        onChange({
            values: values,
            raw_values: raw,
        });
    }

    const listCities = () => {
        (async () => {
            try {
                loadingSet(true);
                let _cities = await settingsService.citiesList();
                if (data.city) {
                    const selected = _cities.filter((item) => {
                        return data.city.indexOf(item.city_id + '') > -1;
                    });
                    form.api.setValue('city', selected)
                }

                citiesSet(_cities);

            } catch (e) {
                console.error(e);
            }
            finally {
                loadingSet(false);
            }
        })();
    };

    useEffect(() => {
        listCities()
    }, []);

    useEffect(() => {
        if (form) {
            form.api.setValue('name', data?.name || '');
            form.api.setValue('email', data?.email || '');
            form.api.setValue('mobile', data?.mobile || '');
            form.api.setValue('gender', Gender[data?.gender]);
            form.api.setValue('admin', BooleanOption[data?.admin]);
            form.api.setValue('active', BooleanOption[data?.active]);
        }
    }, [data]);

    return (
        <form autoComplete="off" className="user-filters-form-component">

            <div className="row">
                <div className="col">
                    <div>
                        <FormTextField
                            fullWidth
                            autoComplete='none'
                            form={form}
                            name={'name'}
                            label={'Name'}
                        />
                    </div>
                </div>
                <div className="col">
                    <div>
                        <SelectField
                            options={Genders}
                            form={form}
                            name={'gender'}
                            label={'Gender'}
                        />
                    </div>
                </div>
            </div>
            <div className="space-lg mb-2" />
            <div className="row">
                <div className="col">
                    <div>
                        <FormTextField
                            fullWidth
                            autoComplete='none'
                            form={form}
                            name={'email'}
                            label={'Email'}
                        />
                    </div>
                </div>
                <div className="col">
                    <div>
                        <FormTextField
                            fullWidth
                            autoComplete='none'
                            form={form}
                            name={'mobile'}
                            label={'Mobile'}
                        />
                    </div>
                </div>
            </div>
            <div className="space-lg mb-2" />

            <div>
                <SelectField
                    multiple={true}
                    options={cities}
                    isLoading={loading}
                    isDisabled={loading}
                    form={form}
                    name={'city'}
                    label={'City'}
                    keyBy={'city_id'}
                    labelBy={'label'}
                />
            </div>
            <div className="space-lg mb-2" />
            <div className="row">
                <div className="col">
                    <div>
                        <SelectField
                            options={BooleanOptions}
                            form={form}
                            name={'admin'}
                            label={'Admin'}
                        />
                    </div>
                </div>
                <div className="col">
                    <div>
                        <SelectField
                            options={BooleanOptions}
                            form={form}
                            name={'active'}
                            label={'Active Users'}
                        />
                    </div>
                </div>
            </div>

            <div className="space-lg mb-2" />
            <div className="d-flex justify-content-end">
                <LoadingButton
                    type={'submit'}
                    variant="outlined"
                    endIcon={<SearchIcon />}
                    onClick={form.handleSubmit(onSubmitSuccess)}>
                    Search
                </LoadingButton>
            </div>
        </form>
    );
}

export default UserFiltersForm;
