import { store } from 'react-notifications-component';

const notification = {
    message: "Configurable",
    type: "success",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated animate__faster animate__fadeInDown"],
    animationOut: ["animate__animated animate__faster animate__fadeOut"],
    dismiss: {
        duration: 3000,
        pauseOnHover: true,
        showIcon: true,
    },
    slidingEnter: {
        duration: 300,
        timingFunction: 'ease-out',
        delay: 0
    },
    slidingExit: {
        duration: 300,
        timingFunction: 'ease-out',
        delay: 0
    }
};

const HrcToast = {};

HrcToast.success = (message) => {
    store.addNotification({
        ...notification,
        message: message
    })
};

HrcToast.danger = (message) => {
    store.addNotification({
        ...notification,
        message: message,
        type: "danger"
    })
};

HrcToast.info = (message) => {
    store.addNotification({
        ...notification,
        message: message,
        type: "info"
    })
};

HrcToast.alert = (message) => {
    store.addNotification({
        ...notification,
        message: message,
        type: "warning"
    })
};

HrcToast.default = (message) => {
    store.addNotification({
        ...notification,
        message: message,
        type: "default"
    })
};

HrcToast.BEMessage = function (messageWrapper, options = { success: true }) {
    const text = messageWrapper.text;

    if (messageWrapper.type === 'ALERT') {
        HrcToast.alert(text);
    }
    else if (messageWrapper.type === 'INFO') {
        HrcToast.info(text);
    }
    else if (messageWrapper.type === 'MESSAGE' && options.success) {
        HrcToast.success(text);
    }
    else if (messageWrapper.type === 'MESSAGE' && !options.success) {
        HrcToast.danger(text);
    }
    else {
        HrcToast.default(text);
    }
};

HrcToast.BEMessages = function (messages = [], options = { success: true }) {
    messages.forEach((messageWrapper) => {
        HrcToast.BEMessage(messageWrapper, options);
    });
};

export default HrcToast;
