import React from 'react';

export const getError = (field, errors, options = { className: "input-invalid-message" }) => {
    if(errors[field]){
        return errors[field].message
    }
    return '';
};


