//@flow
import HrcInput from "hays/types/Input";
export default class UserListInput extends HrcInput{

    //query
    page: number;
    name: string;
    mobile: string;
    email: string;
    gender: string;
    admin: string;
    active: string;
    cities_ids: number[];
    limit: number;
    paged: boolean;

    constructor() {
        super();
        this._query = [
            'page',
            'name',
            'mobile',
            'email',
            'gender',
            'admin',
            'active',
            'cities_ids',
            'limit',
            'paged',
        ];
    }

}
