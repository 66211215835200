import ContactStatusConst from "modules/contact/constants/contact-status.const";

const getContactSearchFilters = () => {
    return {

    };
}
export { getContactSearchFilters as getContactSearchFilters };

const contactStatus = {
    [ContactStatusConst.NEW]: {
        key: ContactStatusConst.NEW,
        label: 'New',
        color: '#053c71'
    },
    [ContactStatusConst.IN_PROGRESS]: {
        key: ContactStatusConst.IN_PROGRESS,
        label: 'In Progress',
        color: '#F79F1F'
    },
    [ContactStatusConst.CANCELLED]: {
        key: ContactStatusConst.CANCELLED,
        label: 'Cancelled',
        color: '#EA2027'
    },
    [ContactStatusConst.DONE]: {
        key: ContactStatusConst.DONE,
        label: 'Done',
        color: '#12CBC4'
    },
}

export { contactStatus as contactStatus };

const getContactStatus = () => {
  return [
      contactStatus[ContactStatusConst.NEW],
      contactStatus[ContactStatusConst.IN_PROGRESS],
      contactStatus[ContactStatusConst.DONE],
      contactStatus[ContactStatusConst.CANCELLED],
  ];
};

export { getContactStatus as getContactStatus };
