
import React, {useEffect, useState} from 'react';
import Dialog from "@mui/material/Dialog";
import UserFiltersForm from "modules/user/components/user-filters-form/user-filters-form.component";
import {DialogContent, DialogTitle} from "@mui/material";
import UserEdit from "modules/user/components/user-edit/user-edit.component";
import ContactEdit from "modules/contact/components/contact-edit/contact-edit.component";

const ContactModalApi = {
    show: () => {},
}
export { ContactModalApi as ContactModalApi };

function ContactModal(props) {

    const {
        onChange = () => {},
        ...rest
    }  = props;

    const [modalShow, setModalShow] = useState(false);
    const [contact, contactSet] = useState(null);

    const show = (show = true, showOptions = {}) => {
        contactSet(showOptions.contact);
        setModalShow(show);
    };

    useEffect(() => {
        ContactModalApi.show = show;
    }, []);

    return (
        <Dialog fullWidth={true} maxWidth={'sm'} open={modalShow} onClose={() => setModalShow(false)} className="hays-md-modal"  {...rest} >

            {
                (!!modalShow) &&
                <>
                    <DialogTitle>
                        Ticket
                    </DialogTitle>
                    <DialogContent>
                        <div className="space" />
                        <ContactEdit contact={contact} onChange={onChange}/>
                    </DialogContent>
                </>
            }


        </Dialog>
    );

}

export default ContactModal;
