import React, { useState } from "react";
import {FormTextField, useForm} from "hays/form";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckIcon from "@mui/icons-material/Check";
import "./login.form.scss";
import Joi from "hays/joi";
import AuthApi from "modules/auth/apis/auth.api";
import AuthLoginInput from "modules/auth/inputs/auth-login.input";
import {addToRequestHeader} from "hays/http";

const schema = {
    email: Joi.string().required(),
    password: Joi.string().required(),
};

const initialValues = {
    email: "",
    password: "",
};

function LoginForm(props) {

    const {
        onSuccess = () => {},
    } = props

    const form = useForm({initialValues, schema});
    const [loading, setLoading] = useState(false);

    const onSubmitSuccess = ({values}) => {
        setLoading(true);

        (async () => {
            try {
                const inputs = new AuthLoginInput();
                inputs.identifier = values.email
                inputs.identifier_type = 'EMAIL'
                inputs.password = values.password;
                const result = await AuthApi.login(inputs);
                if (!!result?.user?.admin) {
                    localStorage.setItem('token', result.token);
                    window.location.reload();
                }

            } catch (e) {
                console.error(e);
            }
            finally {
                setLoading(false);
            }
        })();
    };

    return (
        <div className="login-form">
            <form onSubmit={form.handleSubmit(onSubmitSuccess)} >

                <FormTextField
                    fullWidth
                    form={form}
                    name={'email'}
                    label={'Email'}
                />
                <div className="space-md mb-2"/>
                <FormTextField
                    fullWidth
                    form={form}
                    type={'password'}
                    name={'password'}
                    label={'Password'}
                />
                <div className="space-md mb-2"/>
                <LoadingButton
                    fullWidth={true}
                    variant="outlined"
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<CheckIcon />}
                    onClick={form.handleSubmit(onSubmitSuccess)}>
                    Login
                </LoadingButton>

            </form>
        </div>

    );
}

export default LoginForm;
