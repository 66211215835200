import Http from "hays/http";
import NotificationSaveInput from "modules/notification/inputs/notification-save.input";
import NotificationListInput from "modules/notification/inputs/notification-list.input";
import NotificationDeleteInput from "modules/notification/inputs/notification-delete.input";
import NotificationNotifyInput from "modules/notification/inputs/notification-notify.input";
const NotificationApi = {};

NotificationApi.list = async (inputs: NotificationListInput) => {
    return Http.get('/notifications', inputs.query())
};

NotificationApi.save = async (inputs: NotificationSaveInput) => {
    return Http.post('/notifications', inputs.payload())
};

NotificationApi.delete = async (inputs: NotificationDeleteInput) => {
    return Http.delete('/notifications/' + inputs.notification_id);
};

NotificationApi.notify = async (inputs: NotificationNotifyInput) => {
    return Http.post('/notifications/' + inputs.notification_id + '/notify');
};

export default NotificationApi;
