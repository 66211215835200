import http from "hays/http";
import CityAddInput from "modules/settings/inputs/city-add.input";
import CityUpdateInput from "modules/settings/inputs/city-update.input";
import CityDeleteInput from "modules/settings/inputs/city-delete.input";
import CityListInput from "modules/settings/inputs/city-list.input";


const CityApi = {};

CityApi.list = async (inputs: CityListInput) => {
    return http.get('/cities', inputs.query())
};

CityApi.create = async (inputs: CityAddInput) => {
    return http.post('/cities', inputs.payload())
};

CityApi.update = async (inputs: CityUpdateInput) => {
    return http.put('/cities/' + inputs.city_id, inputs.payload())
};

CityApi.delete = async (inputs: CityDeleteInput) => {
    return http.delete('/cities/' + inputs.city_id)
};

export default CityApi;
