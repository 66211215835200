//@flow
import HrcInput from "hays/types/Input";
export default class CityListInput extends HrcInput{

    //query
    with_parent: boolean;

    constructor() {
        super();
        this._query = [
            'with_parent',
        ];
    }

}
