//@flow
import HrcInput from "hays/types/Input";
export default class ContactUpdateInput extends HrcInput{

    // route
    contact_id: number;

    //payload
    status: string;
    followed_id: number;

    constructor() {
        super();
        this._payload = [
            'status',
            'followed_id',
        ];
    }

}
