import React, { useState } from 'react';
import {FormTextField, useForm} from "hays/form";
import Joi from "hays/joi";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckIcon from "@mui/icons-material/Check";
import FileUploadModal, {FileUploadModalApi} from "modules/file-manager/modals/file-upload/file-upload.modal";
import {AllFileUploadAllowedImagesTypes} from "modules/file-manager/components/file-upload/file-upload.component";
import FileManagerFolderConst from "modules/file-manager/constants/file-manager-folder.const";
import {Button, FormLabel} from "@mui/material";
import './widget-home-slider-form.component.scss';

const schema = {
    file: Joi.object().required().error(new Error('required')),
    title_ar: Joi.string().required(),
    link_ar: Joi.string().uri().required(),
    title_en: Joi.string().required(),
    link_en: Joi.string().uri().required(),
    sort: Joi.number().required(),
};

const initialValues = {
    file: null,
    title_ar: '',
    link_ar: '',
    title_en: '',
    link_en: '',
    sort: 1,
};

function WidgetHomeSliderForm(props) {

    const {
        onChange = () => {},
    } = props;

    const [loading, loadingSet] = useState(false);

    const form = useForm({initialValues, schema});

    const onFileUploadModalChange = (payload) => {
        form.api.setValue('file', payload.data.file);
    };

    const onSubmitSuccess = ({values}) => {
        onChange({
            type: 'ADD',
            data: values
        });
    }

    const showUpload = () => {
        FileUploadModalApi.show();
    };

    if (!form) {
        return <></>;
    }

    return (
        <div className="widget-home-slider-form-component">
            <FileUploadModal
                onChange={onFileUploadModalChange}
                folder={FileManagerFolderConst.UPLOAD_IMAGES}
                allowedTypes={AllFileUploadAllowedImagesTypes} />

            <div className="mb-4">
                <FormLabel id="demo-row-radio-buttons-group-label">Image (4:3 Ratio)</FormLabel>
                <div>
                    <Button color="secondary" onClick={showUpload}>Select Image</Button>

                    {
                        !!(form && form?.values && form?.values?.file) &&
                        <div>
                            <img width="100px;" src={form?.values?.file?.s3_url} alt=""/>
                        </div>
                    }
                    {
                        !!(form && form?.errors && form?.errors?.file) &&
                        <div className="error">
                            Image is required
                        </div>
                    }
                </div>
            </div>
            <div>
                <FormTextField
                    fullWidth
                    form={form}
                    name={'title_ar'}
                    label={'Title Ar'}
                />
            </div>
            <div className="space-lg mb-2" />
            <div>
                <FormTextField
                    fullWidth
                    form={form}
                    name={'link_ar'}
                    label={'Link Ar'}
                />
            </div>
            <div className="space-lg mb-2" />
            <div>
                <FormTextField
                    fullWidth
                    form={form}
                    name={'title_en'}
                    label={'Title En'}
                />
            </div>
            <div className="space-lg mb-2" />

            <div>
                <FormTextField
                    fullWidth
                    form={form}
                    name={'link_en'}
                    label={'Link En'}
                />
            </div>
            <div className="space-lg mb-2" />
            <div>
                <FormTextField
                    fullWidth
                    form={form}
                    type={'number'}
                    name={'sort'}
                    label={'Sort'}
                />
            </div>
            <div className="space-lg mb-2" />
            <div>
                <LoadingButton
                    variant="outlined"
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<CheckIcon />}
                    onClick={form.handleSubmit(onSubmitSuccess)}>
                    Save
                </LoadingButton>
            </div>

        </div>
    );
}

export default WidgetHomeSliderForm;
