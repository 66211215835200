import http from "hays/http";
import UserListInput from "modules/user/inputs/user-list.input";
import UserUpdateInput from "modules/user/inputs/user-update.input";
import CustomerUserUnlinkByUserInput from "modules/user/inputs/customer-user-unlink-by-user.input";
const UserApi = {};

UserApi.list = async (inputs: UserListInput) => {
    return http.get('/users', inputs.query())
};

UserApi.update = async (inputs: UserUpdateInput) => {
    return http.put('/users/' + inputs.user_id, inputs.payload(), inputs.query())
};

UserApi.customerUnlink = async (inputs: CustomerUserUnlinkByUserInput) => {
    return http.post('/users/' + inputs.user_id + '/customers/unlink')
};

export default UserApi;
