//@flow
import HrcInput from "hays/types/Input";
export default class WidgetUpdateInput extends HrcInput{


    //payload
    type: string;
    config: string;


    constructor() {
        super();
        this._payload = [
            'type',
            'config',
        ];
    }

}
