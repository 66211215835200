import Joi from "@hapi/joi";
import messages from "./messages"

const _HalaJoi = Joi.defaults((schema) => {

    const allMessages = {...messages.any, ...messages.string, ...messages.number, ...messages.array};

    return schema.messages(allMessages);

});

export default _HalaJoi;
