import React, {useEffect, useState} from 'react';
import {SelectField, DatePickerField, useForm} from "hays/form";
import {contactStatus, getContactStatus} from "modules/contact/utils/contact.utils";
import UserListInput from "modules/user/inputs/user-list.input";
import UserApi from "modules/user/apis/user.api";
import "./contact-filters.component.scss"
import LoadingButton from "@mui/lab/LoadingButton";
import CheckIcon from "@mui/icons-material/Check";
import _ from "lodash";
import moment from "moment-timezone";
import settingsService from "modules/settings/others/settings.service";


const ContactFiltersEvents = {
  SUBMIT: 'SUBMIT',
  DIRTY: 'DIRTY',
  VALUES_CHANGED: 'VALUES_CHANGED',
};
export { ContactFiltersEvents as ContactFiltersEvents };


const schema = {
};

const initialValues = {
    status: null,
    follow_by: null,
    date_from: null,
    date_to: null,
};

const ContactStatus = getContactStatus();

function ContactFilters(props) {

    const {
        onChange = () => {},
        data = {}
    } = props;

    const form = useForm({initialValues, schema});
    const [loading, loadingSet] = useState(false);
    const [admins, adminsSet] = useState([]);
    const [value, setValue] = React.useState(null);
    const [rawValues, rawValuesSet] = React.useState({});


    const listUsers = (_query) => {
        (async () => {
            try {
                loadingSet(true);
                const admins = await settingsService.adminsList();
                adminsSet(admins);
                loadingSet(false);

            } catch (e) {
                console.error(e);
                loadingSet(false);
            }
        })();
    }

    const onSubmitSuccess = ({values}) => {

        const _values = values;
        const _raw_values = {};
        if (_values.date_from && _values.date_from._isValid) {
            _raw_values.date_from = _values.date_from.format('YYYY-MM-DD');
        }
        if (_values.date_to && _values.date_to._isValid) {
            _raw_values.date_to = _values.date_to.format('YYYY-MM-DD');
        }

        if (_values.status) {
            _raw_values.status = _values.status.key
        }

        if (_values.follow_by) {
            _raw_values.follow_by = _values.follow_by.user_id + ''
        }

        rawValuesSet(_raw_values);

        onChange({
            type: ContactFiltersEvents.SUBMIT,
            data: {
                values: values,
                raw_values: _raw_values,
                dirty: form.dirty
            }
        });
        form.api.setFormDirty(false);
    }

    useEffect(() => {
        onChange({
            type: ContactFiltersEvents.VALUES_CHANGED,
            data: {
                values: form.values,
                dirty: form.dirty
            }
        });
    }, [form.values]);

    useEffect(() => {
        onChange({
            type: ContactFiltersEvents.DIRTY,
            data: {
                values: form.values,
                dirty: form.dirty
            }
        });
    }, [form.dirty]);

    useEffect(() => {
        listUsers();
    }, []);


    useEffect(() => {
        const _data = _.pick(data, ['status', 'follow_by', 'date_from', 'date_to'])
        if (!_.isEqual(_data, rawValues)) {
            form.api.setValue('status', contactStatus[_data.status]);
            if (_data.date_from) {
                form.api.setValue('date_from', moment(_data.date_from));
            }
            else {
                form.api.setValue('date_from', null);
            }
            if (_data.date_to) {
                form.api.setValue('date_to', moment(_data.date_to));
            }
            else {
                form.api.setValue('date_to', null);
            }
        }
    }, [data]);

    useEffect(() => {
        if (data.follow_by) {
            form.api.setValue('follow_by', admins.find((item) => {
                return item.user_id + '' === data.follow_by;
            }))
        }
    }, [admins, data]);

    return (
        <div className="contact-filters-component">
            <div className="row">
                <div className="col">
                    <SelectField
                        placeholder={'All'}
                        options={ContactStatus}
                        form={form}
                        name={'status'}
                        label={'Status'}
                    />
                </div>
                <div className="col">
                    <SelectField
                        placeholder={'All'}
                        options={admins}
                        form={form}
                        name={'follow_by'}
                        isLoading={loading}
                        isDisabled={loading}
                        label={'Follow By'}
                        keyBy={'user_id'}
                        labelBy={'name'}
                    />
                </div>
                <div className="col">
                    <DatePickerField
                        form={form}
                        name={'date_from'}
                        label={'Date From'}
                        clearable={true}
                    />
                </div>
                <div className="col" >
                    <DatePickerField
                        form={form}
                        name={'date_to'}
                        label={'Date To'}
                        clearable={true}
                    />
                </div>
                <div className="col d-flex align-items-center">
                    {
                        (!!form.dirty) &&
                        <LoadingButton
                            fullWidth
                            size="large"
                            variant="outlined"
                            loading={loading}
                            loadingPosition="start"
                            startIcon={<CheckIcon />}
                            onClick={form.handleSubmit(onSubmitSuccess)}>
                            Search
                        </LoadingButton>
                    }

                </div>
            </div>
        </div>
    );
}

export default ContactFilters;
