//@flow
import HrcInput from "hays/types/Input";
export default class CustomerUserUnlinkByUserInput extends HrcInput{

    // route
    user_id: number;

    //payload

    //query

    constructor() {
        super();
        this._payload = [

        ];
    }

}
