import React, {useEffect, useState} from 'react';
import NotificationStoreModal, {NotificationStoreModalApi} from "modules/notification/modals/notification-store/notification-store.modal";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import NotificationApi from "modules/notification/apis/notification.api";
import NotificationListInput from "modules/notification/inputs/notification-list.input";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {LinearProgress} from "@mui/material";
import moment from "moment/moment";
import AppConst from "modules/main/constants/app.const";
import _ from "lodash";
import Pagination from "@mui/material/Pagination";
import {useQuery} from "hays/hooks/use-query";
import {useSearchParams} from "react-router-dom";
import SendIcon from '@mui/icons-material/Send';
import {
    NotificationStoreActions
} from "modules/notification/components/notification-store/notification-store.component";

const getRequestId = () => {
    return  _.uniqueId('notifications-request-');
}
let lastRequestId = getRequestId();

function NotificationsContainer() {

    const query = useQuery({});
    let [searchParams, setSearchParams] = useSearchParams();

    const [notifications, notificationsSet] = useState([]);
    const [loading, loadingSet] = useState(false);
    const [showContent, showContentSet] = useState(true);
    const [lastPage, lastPageSet] = useState(0);
    const [page, pageSet] = useState(1);

    const openModal = (notification = null, action = NotificationStoreActions.CREATE) => {
        NotificationStoreModalApi.show(true, {
            notification: notification,
            action: action
        });
    };

    const onPageChange = (event, page) => {
        setSearchParams({
            ...query,
            page: page
        });
    };

    const notificationsContent = notifications.map((item) => {
        return (
            <TableRow
                key={item.notification_id}
                hover
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell scope="row">
                    <Button size={'small'} onClick={() => openModal(item, NotificationStoreActions.EDIT)}>
                        Edit
                    </Button>
                </TableCell>
                <TableCell style={{width: 200}}>
                    {item.title_ar}
                    <br/>
                    {item.title_en}
                </TableCell>
                <TableCell style={{width: 350}}>
                    {item.body_ar}
                    <br/>
                    {item.body_en}
                </TableCell>
                <TableCell style={{width: 350}}>
                    <a href={item.link_ar} target="_blank">
                        Arabic
                    </a>
                    <br/>
                    <a href={item.link_en} target="_blank">
                        English
                    </a>
                </TableCell>
                <TableCell>
                    <a href={item.image_ar} target="_blank">
                        <img style={{width: 30}} src={item.image_ar} alt=""/>
                    </a>
                    <div className="space-sm"/>
                    <a href={item.image_en} target="_blank">
                        <img style={{width: 30}} src={item.image_en} alt=""/>
                    </a>
                </TableCell>
                <TableCell>
                    {
                        (!!item.sent_at) && <span>{item.successfully_received_count}/{item.receivers_count}</span>
                    }
                    {
                        (!item.sent_at) &&
                        <Button aria-label="delete"  color="success"  variant="contained" size={'small'} onClick={() => openModal(item, NotificationStoreActions.NOTIFY)}>
                            <SendIcon style={{fontSize: 10}}/> <span className="ms-1">Notify</span>
                        </Button>
                    }

                </TableCell>
                <TableCell>
                    {item.created_at_formatted}
                </TableCell>
            </TableRow>
        );
    });

    const getList = (_query) => {
        (async () => {
            try {
                const requestId = getRequestId();
                lastRequestId = requestId;

                loadingSet(true);
                const inputs = new NotificationListInput();
                inputs.page = _query.page;
                const result = await NotificationApi.list(inputs);

                if (requestId === lastRequestId) {
                    const data = result.notifications.data || [];
                    data.forEach((item) => {
                        item.created_at_formatted = moment(item.created_at).format(AppConst.DTAE_TIME_FORMAT);
                        if (item.sent_at) {
                            item.sent_at_formatted = moment(item.sent_at).format(AppConst.DTAE_TIME_FORMAT);
                        }
                    });
                    lastPageSet(result.notifications.last_page === 1 ? 0 : result.notifications.last_page);
                    notificationsSet(result.notifications.data);
                }

            } catch (e) {
                console.error(e);
            }
            finally {
                loadingSet(false);
            }
        })();
    };

    const onNotificationStoreModalChange = (payload) => {
        if (payload.action === NotificationStoreActions.CREATE || payload.action === NotificationStoreActions.DELETE) {
            if (parseInt(page) === 1) {
                getList(query);
            }
            else {
                onPageChange(null, 1);
            }

        }
        else if (payload.action === NotificationStoreActions.NOTIFY) {
            getList(query);
        }
        else if (payload.action === NotificationStoreActions.EDIT) {
            getList(query);
        }

        NotificationStoreModalApi.show(false, {
            notification: null,
            action: null
        });

    }

    useEffect(() => {

        pageSet(query.page? parseInt(query.page) : 1);
        if (!query.first_change) {
            getList(query);
        }
    }, [query]);


    return (
        <div className="notifications-container">

            <NotificationStoreModal onChange={onNotificationStoreModalChange}/>
            <div className="d-flex justify-content-between align-items-center">
                <Button aria-label="delete" color="primary" onClick={openModal}>
                    <AddIcon /> Add
                </Button>
                {
                    (showContent && (lastPage > 0)) &&
                    <div style={{minWidth: '350px', justifyContent: 'flex-end', display: 'flex'}}>
                        <Pagination count={lastPage} page={page} onChange={onPageChange} />
                    </div>
                }
            </div>


            <div className="space-lg"/>
            {
                (loading) &&
                <>
                    <div className="space-lg"/>
                    <div className="space-lg"/>
                    <LinearProgress />

                </>
            }

            {
                (!loading && showContent) &&
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell >Title</TableCell>
                                <TableCell >Body</TableCell>
                                <TableCell >Link</TableCell>
                                <TableCell >Image</TableCell>
                                <TableCell >Receivers</TableCell>
                                <TableCell >Created At</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {notificationsContent}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </div>
    );
}

export default NotificationsContainer;
