//@flow
import HrcInput from "hays/types/Input";
export default class CityUpdateInput extends HrcInput{

    // route
    city_id: number;

    //payload
    name_ar: string;
    name_en: string;
    ignore_similar_names: boolean;

    constructor() {
        super();
        this._payload = [
            'name_ar',
            'name_en',
            'ignore_similar_names'
        ];
    }

}
