//@flow
import HrcInput from "hays/types/Input";
export default class CityAddInput extends HrcInput{


    //payload
    ignore_similar_names: boolean;
    name_ar: string;
    name_en: string;
    parent_city_id: string;


    constructor() {
        super();
        this._payload = [
            'ignore_similar_names',
            'name_ar',
            'name_en',
            'parent_city_id',
        ];
    }

}
