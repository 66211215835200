import Dialog from "@mui/material/Dialog";
import React, {useEffect, useState} from 'react';
import DialogTitle from "@mui/material/DialogTitle";
import {CityAddFormMode} from "modules/dashboard/forms/city-add/city-add.form";
import {DialogActions, DialogContent} from "@mui/material";
import FileUpload from "modules/file-manager/components/file-upload/file-upload.component";

const FileUploadModalApi = {};
export {FileUploadModalApi as FileUploadModalApi};

function FileUploadModal(props) {

    const {
        allowedTypes = [],
        folder = null,
        onChange = () => {},
        ...rest
    }  = props;

    const [modalShow, setModalShow] = useState(false);
    const [extra, extraSet] = useState(false);

    const show = (show = true, showOptions = {}) => {
        extraSet(showOptions.extra);
        setModalShow(show);
    };

    useEffect(() => {
        FileUploadModalApi.show = show;
    }, []);

    return (
        <Dialog open={modalShow} onClose={() => setModalShow(false)} className="hays-md-modal"  {...rest} >
            <div className="hrc-modal file-upload-modal">

                <FileUpload
                    onHide={() => setModalShow(false)}
                    onChange={onChange}
                    folder={folder}
                    extra={extra}
                    allowedTypes={allowedTypes} />
            </div>
        </Dialog>
    );

}


export default FileUploadModal;
