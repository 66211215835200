//@flow
import HrcInput from "hays/types/Input";
export default class ContentListInput extends HrcInput{

    // route
    example1: number;

    //payload
    example2: number;

    //query

    constructor() {
        super();
        this._payload = [
            'example2',
        ];
    }

}