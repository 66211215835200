import React, {useEffect, useState} from 'react';
import {useQuery} from "hays/hooks/use-query";
import {useSearchParams} from "react-router-dom";
import {getContactSearchFilters} from "modules/contact/utils/contact.utils";
import _ from "lodash";
import Pagination from "@mui/material/Pagination";
import ContactListInput from "modules/contact/inputs/contact-list.input";
import ContactApi from "modules/contact/apis/contact.api";
import {LinearProgress} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import ContactStatus from "modules/contact/components/contact-status/contact-status.component";
import moment from "moment/moment";
import AppConst from "modules/main/constants/app.const";
import ContactFilters, {
    ContactFiltersEvents
} from "modules/contact/components/contact-filters/contact-filters.component";
import "./tickets.container.scss"
import UserEditModal, {UserEditModalApi} from "modules/user/modals/user-edit/user-edit.modal";
import ContactModal, {ContactModalApi} from "modules/contact/modals/contact/contact.modal";

const getRequestId = () => {
    return  _.uniqueId('contact-request-');
}
let lastRequestId = getRequestId();

function TicketsContainer() {

    const query = useQuery(getContactSearchFilters());
    let [searchParams, setSearchParams] = useSearchParams();

    const [list, listSet] = useState([]);
    const [loading, loadingSet] = useState(false);
    const [page, pageSet] = useState(1);
    const [lastPage, lastPageSet] = useState(0);
    const [filtersValues, filtersValuesSet] = useState({});
    const [showContent, showContentSet] = useState(true);
    const [userView, userViewSet] = useState(true);

    const onPageChange = (event, page) => {
        setSearchParams({
            ...query,
            page: page
        });
    };

    const onUserClick = (item) => {

        UserEditModalApi.show(true, {
            user: item.user
        });
    };

    const openContactModal = (item) => {
        ContactModalApi.show(true, {
            contact: item
        })
    }

    const listContent = list.map((item) => {
        return (
            <TableRow
                key={item.contact_id}
                hover
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell scope="row">
                    {item.contact_id}
                </TableCell>
                <TableCell className="cursor-pointer" onClick={() => onUserClick(item)}>
                    {item.user.name}
                    <div className="space-xs" />
                    <small className="text-muted">{item.user.mobile}</small>
                </TableCell>
                <TableCell
                    className="cursor-pointer"
                    onClick={() => openContactModal(item)}>
                    <ContactStatus status={item.status} />
                </TableCell>
                <TableCell style={{maxWidth: '200px'}}>{item.message}</TableCell>
                <TableCell >{item?.follow_by?.name || ''}</TableCell>
                <TableCell >{item.created_at_formatted}</TableCell>
            </TableRow>
        );
    });

    const listTickets = (_query) => {
        (async () => {
            try {
                loadingSet(true);
                const requestId = getRequestId();
                lastRequestId = requestId;

                const inputs = new ContactListInput();
                if (_query.user_id) {
                    inputs.user_id = _query.user_id;
                }
                else {
                    inputs.limit = 10;
                    inputs.page = _query.page;
                    inputs.status = _query.status;
                    inputs.follow_by = _query.follow_by;
                    inputs.date_from = _query.date_from;
                    inputs.date_to = _query.date_to;
                }

                const result = await ContactApi.list(inputs);

                if (requestId === lastRequestId) {
                    const _list = (_query.user_id ? result?.contact_list : result?.contact_list?.data).map((item) => {
                        item.created_at_formatted = moment(item.created_at).format(AppConst.DTAE_TIME_FORMAT)
                        return item;
                    });
                    listSet(_list);
                    if (!_query.user_id) {
                        lastPageSet(result.contact_list.last_page === 1 ? 0 : result.contact_list.last_page);
                    }

                    loadingSet(false);
                }


            } catch (e) {
                console.error(e);
                loadingSet(false);
            }
        })();
    };

    const onContactFiltersChange = (payload) => {

        if (payload.type === ContactFiltersEvents.DIRTY) {
            if (payload.data.dirty) {
                showContentSet(false);
            }
        }
        else if (payload.type === ContactFiltersEvents.SUBMIT) {
            showContentSet(true);
            setSearchParams({
                page: 1,
                ...payload.data.raw_values
            });
        }
    };

    const onContactModalChange  = () => {
        listByQuery(query);
    }

    const listByQuery = (query) => {
        if (query.user_id) {
            userViewSet(true);
            listTickets({
                user_id: query.user_id
            });
        }
        else {
            filtersValuesSet(query);
            userViewSet(false);
            listTickets(query);
        }
    }

    useEffect(() => {

        pageSet(query.page? parseInt(query.page) : 1);
        if (!query.first_change) {
            listByQuery(query);
        }
    }, [query]);

    return (
        <div className="tickets-container">

            <UserEditModal readOnly={true}/>
            <ContactModal onChange={onContactModalChange}/>

            {
                (!userView) &&
                <>
                    <ContactFilters data={filtersValues} onChange={onContactFiltersChange}/>
                    <div className="space-lg"/>
                </>
            }

            <div className="users-header">

                {
                    (!userView && showContent && (lastPage > 0)) &&
                    <div style={{minWidth: '350px', justifyContent: 'flex-end', display: 'flex'}}>
                        <Pagination count={lastPage} page={page} onChange={onPageChange} />
                    </div>
                }
            </div>

            <div className="space-lg"/>
            {
                (loading) &&
                <>
                    <div className="space-lg"/>
                    <div className="space-lg"/>
                    <LinearProgress />

                </>
            }

            {
                (!loading && showContent) &&
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell >User</TableCell>
                                <TableCell >Status</TableCell>
                                <TableCell >Message</TableCell>
                                <TableCell >Follow By</TableCell>
                                <TableCell >Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listContent}
                        </TableBody>
                    </Table>
                </TableContainer>
            }

        </div>
    );
}

export default TicketsContainer;
