import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {SwitchField, useForm} from "hays/form";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckIcon from "@mui/icons-material/Check";
import UserUpdateInput from "modules/user/inputs/user-update.input";
import UserApi from "modules/user/apis/user.api";
import {Link} from "@mui/material";
import LinkOffIcon from '@mui/icons-material/LinkOff';
import CustomerUserUnlinkByUserInput from "modules/user/inputs/customer-user-unlink-by-user.input";

const schema = {
};

const initialValues = {
    admin: false,
    status: false,
};

function UserEdit(props) {

    const {
        user = null,
        onChange = () => {},
        readOnly = false,
    } = props;

    const form = useForm({initialValues, schema});
    const [loading, loadingSet] = useState(false);

    const onSubmitSuccess = ({values}) => {
        (async () => {
            try {
                const inputs = new UserUpdateInput();
                inputs.setStatusTarget();
                inputs.user_id = user.user_id;
                inputs.admin = values.admin;
                inputs.status = values.status;

                const result = await UserApi.update(inputs);
                onChange();

            } catch (e) {
                console.error(e);
            }
        })();
    };

    const unlink = () => {
        (async () => {
            try {
                loadingSet(true);
                const inputs = new CustomerUserUnlinkByUserInput();
                inputs.user_id = user.user_id;
                const result = await UserApi.customerUnlink(inputs);
                loadingSet(false);
            } catch (e) {
                console.error(e);
                loadingSet(false);
            }
        })();
    };

    useEffect(() => {
        form.api.setValue('admin', user.admin);
        form.api.setValue('status', user.status);
    }, [user]);


    if (!user) {
        return null;
    }

    return (
        <div className="user-edit-component">
            <div className="d-flex align-items-center">
                <div className="flex-grow-1" />

                <div>
                    {
                        !readOnly &&
                        <LoadingButton
                            type={'button'}
                            variant="contained"
                            color={'error'}
                            disabled={loading}
                            loading={loading}
                            loadingPosition="start"
                            startIcon={<LinkOffIcon />}
                            onClick={() => unlink()}>
                            Unlink Customer
                        </LoadingButton>
                    }
                </div>
            </div>
            <div className="space-lg mb-2" />
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        InputProps={{ readOnly: true, }}
                        label="Name"
                        value={user.name}
                        InputLabelProps={{ shrink: true}}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        InputProps={{ readOnly: true, }}
                        label="Gender"
                        value={user.gender}
                        InputLabelProps={{ shrink: true}}
                    />
                </Grid>
            </Grid>
            <div className="space-lg mb-2" />
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        InputProps={{ readOnly: true, }}
                        label="Email"
                        value={user.email}
                        InputLabelProps={{ shrink: true}}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        InputProps={{ readOnly: true, }}
                        label="Mobile"
                        value={user.mobile}
                        InputLabelProps={{ shrink: true}}
                    />
                </Grid>
            </Grid>
            <div className="space-lg mb-2" />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        InputProps={{ readOnly: true, }}
                        label="City"
                        value={(user?.city?.parent?.name_ar || '') + ' - ' + user.city.name_ar}
                        InputLabelProps={{ shrink: true}}
                    />
                </Grid>
            </Grid>
            <div className="space-lg mb-2" />
            <div className="d-flex align-items-center">
                <div className="me-4">
                    <SwitchField
                        readOnly={readOnly}
                        form={form}
                        name={'admin'}
                        label={'Admin'}
                    />
                </div>

                <SwitchField
                    readOnly={readOnly}
                    form={form}
                    name={'status'}
                    label={'Active'}
                />

            </div>

            <div className="space-lg mb-2" />
            <div className="d-flex justify-content-end">
                {
                    !readOnly &&
                    <LoadingButton
                        type={'submit'}
                        variant="outlined"
                        disabled={!form.dirty}
                        loading={loading}
                        loadingPosition="start"
                        startIcon={<CheckIcon />}
                        onClick={form.handleSubmit(onSubmitSuccess)}>
                        Save
                    </LoadingButton>
                }

            </div>

        </div>
    );
}

export default UserEdit;
