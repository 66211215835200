import React, {useEffect, useState} from 'react';
import LoginForm from "modules/auth/forms/login/login.form";
import "./login.container.scss";
import AuthApi from "modules/auth/apis/auth.api";
import {addToRequestHeader} from "hays/http";

function LoginContainer(props) {

    const {
        onSuccess = () => {},
    } = props

    const [loading, loadingSet] = useState(true);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token && token !== 'null') {

            (async () => {
                try {
                    addToRequestHeader('Authorization', 'Bearer ' + token);
                    const result = await AuthApi.me();
                    if (!!result?.user?.admin) {
                        localStorage.setItem('user', JSON.stringify(result.user));
                        onSuccess();
                    }

                } catch (e) {
                    console.error(e);
                    localStorage.setItem('token', null);
                    loadingSet(false);
                }
            })();
        }
        else {
            loadingSet(false);
        }
    }, []);

    return (
        <div className="login-container">
            { loading && <span>Wait .. </span>}
            { !loading && <LoginForm onSuccess={onSuccess} />}

        </div>
    );
}

export default LoginContainer;
