import React, {useEffect, useState} from 'react';
import CitiesUpdate from "modules/settings/components/cities-update/cities-update.component";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import WidgetHomeSlider from "modules/settings/components/widget-home-slider/widget-home-slider.component";
import WidgetApi from "modules/settings/apis/widget.api";
import _ from "lodash";
import WidgetTypeConst from "modules/settings/constants/widget-type.const";
import WidgetHomeGrid from "modules/settings/components/widget-home-grid/widget-home-grid.component";


const Tab = {
    HomeSlider: {
        label: 'Home Slider',
        key: 'HomeSlider',
    },
    HomeGrid: {
        label: 'Home Grid',
        key: 'HomeGrid',
    },
    Cities: {
        label: 'Cities',
        key: 'Cities',
    }
};


const Tabs = [
    Tab.HomeSlider,
    //Tab.HomeGrid,
    //Tab.Cities,
];

function SettingsContainer() {

    const [activeTab, activeTabSet] = useState(Tab.HomeSlider);
    const [widgets, widgetsSet] = useState({});
    const [loading, loadingSet] = useState(false);

    const onChipClick = (item) => {
        activeTabSet(item);
    };

    const listWidgets = () => {
        (async () => {
            try {
                loadingSet(true);
                const result = await WidgetApi.list();
                widgetsSet(_.keyBy(result.widgets, 'type'));
            } catch (e) {
                console.error(e);
            }
            finally {
                loadingSet(false);
            }
        })();
    }

    const chipsContent = Tabs.map((item) => {
        const color = item.key === activeTab?.key ? 'primary' : 'default';
        return (
            <Chip key={item.key} label={item.label} onClick={() => onChipClick(item)} color={color} />
        );
    });

    useEffect(() => {
        listWidgets();
    }, []);

    if (loading) {
        return <div>Loading</div>
    }

    return (
        <div className="settings-container">

            <div className="page-title">
                Settings
            </div>

            <Stack direction="row" spacing={1}>
                {chipsContent}
            </Stack>

            <div className="space" />
            <div className="space-lg" />
            {
                (!!activeTab) &&
                <>
                    {
                        (activeTab.key === Tab.Cities.key) && <CitiesUpdate />
                    }
                    {
                        (activeTab.key === Tab.HomeSlider.key) &&
                        <WidgetHomeSlider
                            onChange={listWidgets}
                            config={widgets[WidgetTypeConst.HOME_SLIDER]?.config || []}/>
                    }
                    {
                        (activeTab.key === Tab.HomeGrid.key) &&
                        <WidgetHomeGrid
                            onChange={listWidgets}
                            config={widgets[WidgetTypeConst.HOME_GRID]?.config || []}/>
                    }

                </>
            }

        </div>
    );
}

export default SettingsContainer;
