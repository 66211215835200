import Http from "hays/http";
import ContactListInput from "modules/contact/inputs/contact-list.input";
import ContactUpdateInput from "modules/contact/inputs/contact-update.input";
const ContactApi = {};

ContactApi.list = async (inputs: ContactListInput) => {
    return Http.get('/contact', inputs.query())
};

ContactApi.update = async (inputs: ContactUpdateInput) => {
    return Http.put('/contact/' + inputs.contact_id, inputs.payload())
};

export default ContactApi;
