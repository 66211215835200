//@flow
import HrcInput from "hays/types/Input";
export default class CityDeleteInput extends HrcInput{

    // route
    city_id: number;

    constructor() {
        super();
        this._payload = [];
    }

}
