import React, {useState, useEffect, useRef, useCallback} from 'react';


let HrcJoi;

const setHrcFormConfig = (config) => {
    if(!config.joi) {
        throw 'joi should present in setHrcFormConfig config'
    }
    HrcJoi = config.joi;
};
export { setHrcFormConfig as setHrcFormConfig };

export default function useHrcForm({ initialValues, schema, casts = {} }) {

    const [values, setValues] = useState(initialValues || {});
    const [errors, setErrors] = useState({});
    const [touched, setTouchedInputs] = useState({});
    const [focused, setFocusedInputs] = useState({});
    const [dirty, setDirty] = useState(false);
    const [valid, setValid] = useState(true);

    const setValue = (name, value, notify = true) => {
        values[name] = value;
        if (notify) {
            setValues({ ...values });
        }
    };

    const setTouched = (name, value = true, notify = true) => {
        if (notify) {
            setTouchedInputs({ ...touched, [name]: value });
        }
        else {
            touched[name] = value;
        }

    };

    const setFormUnTouched = (value = true) => {
        setTouchedInputs({});
    };

    const forceValidate = () => {
        return validate();
    };


    const setErrorsByBE = (BEValidation) => {
        if (!BEValidation) {
            return;
        }
        const _errors = {};
        Object.keys(BEValidation).forEach((key) => {
            _errors[key] = { message: BEValidation[key][0]}
        });
        setErrors(_errors)
    };

    const api = {
        setValue : setValue,
        setFormValues : setValues,
        setTouched: setTouched,
        setFormUnTouched: setFormUnTouched,
        forceValidate: forceValidate,
        setFormDirty: setDirty,
        setErrors: setErrors,
        setErrorsByBE: setErrorsByBE
    };

    const handleChange = (name, value) => {

        setDirty(true);
        setValues({ ...values, [name]: value });
    };

    const handleBlur = (name) => {
        setTouchedInputs({ ...touched, [name]: true });
    };

    const handleFocus = (name) => {
        setFocusedInputs({ ...focused, [name]: true });
    };

    const handleSubmit = useCallback((callback) => {
        return () => {
            const _errors = validate(true, true);
            if (Object.keys(_errors).length === 0)  {
                callback({ values });
            }

        }
    });

    useEffect(() => {
        setValues(initialValues);
        setErrors({});
        setFocusedInputs({});
        setTouchedInputs({});
    }, []);

    const validate = (fillErrors = true, all = false) => {

        const _errors = {};

        const castedValues = {...values};
        for (let key in values) {
            if (casts[key]) {
                castedValues[key] = casts[key](values[key]);
            }
        }

        for (let key in castedValues) {
            if ((focused[key] || all) && schema[key]) {
                const schemaObject = {};
                schemaObject[key] = schema[key];
                const {error} = HrcJoi.object(schemaObject).options({ allowUnknown: true }).validate(castedValues);
                if (error) {
                    if (error && error.details) {
                        _errors[key] = error?.details[0];
                    }
                    else if (error && error.message) {
                        _errors[key] = error
                    }

                    _errors[key].source = 'JOI';
                }
            }

        }
        fillErrors && setErrors(_errors);
        setValid(Object.keys(_errors).length === 0);
        return _errors;
    };

    useEffect(() => {
        validate();
    }, [touched]);

    useEffect(() => {
        validate(dirty);
    }, [values]);

    return {
        values,
        errors,
        touched,
        dirty,
        handleChange,
        handleFocus,
        handleBlur,
        handleSubmit,
        valid,
        api
    };
};
