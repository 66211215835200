import Http from "hays/http";
import S3PresignedUrlGetInput from "modules/file-manager/inputs/s3-presigned-url-get.input";


const FileApi = {};

FileApi.S3PresignedUrl = async (inputs: S3PresignedUrlGetInput) => {
    return Http.get('/files-manager/s3-presigned-url', inputs.payload())
};

FileApi.uploadS3 = (url, file, progressCallback) => {
    return new Promise(function (resolve, reject) {

        const xhr = new XMLHttpRequest();

        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    resolve(xhr)
                }
                else {
                    reject(xhr)
                }
            }
        };

        if (progressCallback) {
            xhr.upload.onprogress = (e) => {
                if (e.lengthComputable) {
                    var percentComplete = (e.loaded / file.size) * 100;
                    progressCallback(percentComplete);
                }
            };
        }

        xhr.open("PUT", url);
        xhr.setRequestHeader('Content-Type', file.type);
        xhr.send(file);
    });
}


export default FileApi;
