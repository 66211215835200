import React, { useState, useEffect } from 'react';
import Dialog from "@mui/material/Dialog";
import {DialogContent, DialogTitle} from "@mui/material";
import NotificationStore from "modules/notification/components/notification-store/notification-store.component";

const NotificationStoreModalApi = {
    show: () => {},
}
export { NotificationStoreModalApi as NotificationStoreModalApi };

function NotificationStoreModal(props) {
    const {
        onChange = () => {},
        ...rest
    }  = props;

    const [modalShow, setModalShow] = useState(false);
    const [notification, notificationSet] = useState(null);
    const [action, actionSet] = useState(null);

    const show = (show = true, showOptions = {}) => {
        notificationSet(showOptions.notification);
        actionSet(showOptions.action);
        setModalShow(show);
    };

    useEffect(() => {
        NotificationStoreModalApi.show = show;
    }, []);

    return (
        <Dialog fullWidth={true} maxWidth={'lg'} open={modalShow} className="hays-md-modal"  onClose={() => setModalShow(false)}  {...rest} >
            <div className="hays-modal notification-store-modal">
                <DialogTitle>
                    Notification
                </DialogTitle>
                <DialogContent>
                    {
                        modalShow &&
                        <NotificationStore
                            notification={notification}
                            action={action}
                            onChange={onChange}
                        />
                    }

                </DialogContent>
            </div>
        </Dialog>
    );

}

export default NotificationStoreModal;
