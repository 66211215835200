const messages = {
    any: {
        'any.invalid': 'invalid value',
        'any.only': 'The selected value is invalid.',
        'any.required': 'required',
        'any.unknown': 'not allowed'
    },
    string: {
        'string.alphanum': 'must only contain alpha-numeric characters',
        'string.base': 'must be a string',
        'string.email': 'must be a valid email',
        'string.empty': 'required',
        'string.hostname': 'must be a valid hostname',
        'string.isoDate': 'must be in iso format',
        'string.length': 'length must be {{#limit}} characters long',
        'string.lowercase': 'must only contain lowercase characters',
        'string.max': 'length must be less than or equal to {{#limit}} characters',
        'string.min': 'length must be at least {{#limit}} characters',
        'string.uppercase': 'must only contain uppercase characters'
    },
    number: {
        'number.base': 'must be a number',
        'number.greater': 'must be greater than {{#limit}}',
        'number.integer': 'must be an integer',
        'number.less': 'must be less than {{#limit}}',
        'number.max': 'must be less than or equal to {{#limit}}',
        'number.min': 'must be larger than or equal to {{#limit}}',
        'number.negative': 'must be a negative number',
        'number.positive': 'must be a positive number',
        'number.precision': 'must have no more than {{#limit}} decimal places',
    },
    array: {
        'array.base': 'must be an array',
        'array.excludes': 'contains an excluded value',
        'array.length': 'must contain {{#limit}} items',
        'array.max': 'must contain less than or equal to {{#limit}} items',
        'array.min': 'must contain at least {{#limit}} items',
        'array.orderedLength': 'must contain at most {{#limit}} items',
        'array.unique': 'contains a duplicate value'
    }
};

export default messages;
