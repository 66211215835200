import React, { useState, useEffect, forwardRef } from 'react';
import UiSelect from "hays/ui/select";

const SelectField = (props) => {

    const {
        form,
        name,
        onChange = (event) => {  },
        onBlur = (event) => {  },
        onFocus = (event) => {  },
        ...rest
    } = props;

    const handleChange = (event, value) => {
        form.handleChange(name, value);
        onChange(event);
    };

    const handleBlur = (event) => {
        onBlur(event);
        form.handleBlur(name);
    };

    const handleFocus = (event) => {
        onFocus(event);
        form.handleFocus(name);
    };

    return (
        <UiSelect
            value={form.values[name]}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onChange={handleChange}
            {...rest}
        />
    );

}

export default SelectField;
