//@flow
import HrcInput from "hays/types/Input";
export default class S3PresignedUrlGetInput extends HrcInput{

    // route

    //payload

    //query
    file_name: string;
    file_mime: string;
    file_extension: string;
    folder: string;
    sub_folder: string;

    constructor() {
        super();
        this._payload = ['file_name', 'file_mime', 'file_extension', 'folder', 'sub_folder'];
    }

}
