import axios from 'axios';
import Toast from "./../toast";

const LOG_REQUEST = false;

let client = axios.create();
let defaultHeader = {};

const setEnvClient = (_env) => {
    client = axios.create({
        baseURL:  _env.url
    });
};
export { setEnvClient as setEnvClient };

const addToRequestHeader = (key, value) => {
    defaultHeader[key] = value
};

export {addToRequestHeader as addToRequestHeader}

const HaysRequest = async function(_options) {

    const { extraOptions } = _options;

    const options = {
        ..._options,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
    };

    options.headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        ...defaultHeader
    };

    if (extraOptions.token) {
        options.headers.Authorization  =  'bearer ' + extraOptions.token;
    }

    if (LOG_REQUEST) {
        console.log('%c  ' + options.method + ' ==> (' + options.url + ') :','background: gray; color: #fff');
        console.log('request params' , options.params);
        console.log('request payload' , options.data);
        console.log('request options', options);
    }


    const onSuccess = function(response) {
        // debug
        if (LOG_REQUEST) {
            console.log('%c  axios response: ' + response.config.baseURL + response.config.url, 'background: green; color: #fff');
            if (response) {
                console.log(response);
            }
        }

        const data = response.data || {};
        if (!data.silent) {
            Toast.BEMessages(data.messages, { success: true });
        }
        return data.data;
    };
    const onError = function(error) {

        if (LOG_REQUEST) {
            // debug
            console.log('%c  axios error start :', 'background: red; color: #fff');
            console.log(error);
            if (error.response) {
                console.log(error.response.status, error.response.config.baseURL + error.response.config.url);
                console.log(error.response);
            }
        }

        const data = error.response && error.response.data ? error.response.data : {};

        if (error.response && error.response.status && error.response.status === 422) {
            const Keys = Object.keys(data.validation);
            if( Keys.length ) {
                const firstMsgKey = Keys[0];
                Toast.danger(data.validation[firstMsgKey][0]);
            }
        }
        if (!data.silent) {
            Toast.BEMessages(data.messages, { success: false });
        }

        LOG_REQUEST && console.log('%c  axios error end','background: red; color: #fff');
        return Promise.reject(data);
    };

    return client(options)
        .then(onSuccess)
        .catch(onError);
};

export const HaysPost = (url, data, query = {}, options = {}) => {
    return HaysRequest({
        method: 'post',
        url: url,
        data: data,
        params: query,
        extraOptions : options
    })
};

export const HaysPut = (url, data, query = {},  options = {}) => {
    return HaysRequest({
        method: 'put',
        url: url,
        data: data,
        params: query,
        extraOptions : options
    })
};

export const HaysGet = (url, query = {}, options = {}) => {
    return HaysRequest({
        method: 'get',
        url: url,
        params: query,
        extraOptions : options
    })
};

export const HaysDelete = (url, query = {}, options = {}) => {
    return HaysRequest({
        method: 'delete',
        url: url,
        params: query,
        extraOptions : options
    })
};



export default HaysRequest;
