import React, { useState } from 'react';
import Toolbar from '@mui/material/Toolbar';
import AppBar from "@mui/material/AppBar";
import './header.component.scss';

const drawerWidth = 240;

function Header() {

    return (
        <div className="header-component">
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar className="header"/>
            </AppBar>
        </div>

    );
}

export default Header;
