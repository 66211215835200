import React, {useEffect, useState} from 'react';
import UserEdit from "modules/user/components/user-edit/user-edit.component";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckIcon from "@mui/icons-material/Check";

function HomeContainer() {

    const [user, userSet] = useState(null);

    const signOut = () => {
        console.log('signOut')
        localStorage.setItem('token', null);
        localStorage.setItem('user', null);
        window.location.reload();
    };

    useEffect(() => {
        const _user = JSON.parse(localStorage.getItem('user'));
        userSet(_user);
    }, []);

    if (!user) {
        return <></>
    }

    return (
        <div className="home-container">

            <div className="d-flex align-content-center justify-content-between">
                <div>
                    Hello {user.name}
                </div>
                <LoadingButton
                    onClick={signOut}
                    variant="outlined">
                    Sign Out
                </LoadingButton>
            </div>

            <div className="space-xl"/>
            <UserEdit
                readOnly={true}
                user={user}
            />



        </div>
    );
}

export default HomeContainer;
