import React, { useState } from 'react';
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from '@mui/icons-material/Mail';
import SettingsIcon from '@mui/icons-material/Settings';
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import PropTypes from 'prop-types';
import { Routes, Route, Link, useNavigate} from "react-router-dom";
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import GroupIcon from '@mui/icons-material/Group';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import HomeIcon from '@mui/icons-material/Home';
import logo from "modules/dashboard/components/header/ic_launcher.png";

const drawerWidth = 240;

function Sidebar(props) {

    let navigate = useNavigate();

    const drawer = (
        <div>
            <Toolbar style={{background: '#053c71'}} onClick={() => { navigate('/') }} className="justify-content-center cursor-pointer">
                <img  src={logo} style={{width: 50, height: 50}}/>
            </Toolbar>
            <Divider />
            <List>
                <ListItem button onClick={() => { navigate('/') }}>
                    <ListItemIcon>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Home'} />
                </ListItem>
                <ListItem button onClick={() => { navigate('/users') }}>
                    <ListItemIcon>
                        <GroupIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Users'} />
                </ListItem>
                <ListItem button onClick={() => { navigate('/tickets') }}>
                    <ListItemIcon>
                        <ConfirmationNumberIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Tickets'} />
                </ListItem>
                <ListItem button onClick={() => { navigate('/notifications') }}>
                    <ListItemIcon>
                        <NotificationsActiveIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Notifications'} />
                </ListItem>
            </List>
            <Divider />
            <List>
                <ListItem button onClick={() => { navigate('/contents') }}>
                    <ListItemIcon>
                        <MenuBookIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Contents'} />
                </ListItem>
                <ListItem button onClick={() => { navigate('/settings') }}>
                    <ListItemIcon>
                        <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Settings'} />
                </ListItem>
            </List>
        </div>
    );

    return (
        <div className="sidebar-component">
            <Box component="nav" sx={{ width: { sm: drawerWidth } }}>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
        </div>
    );
}

Sidebar.propTypes = {
    window: PropTypes.func,
};

export default Sidebar;
