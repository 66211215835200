//@flow
import HrcInput from "hays/types/Input";
export default class UserUpdateInput extends HrcInput{

    // route
    user_id: number;

    //query
    target: string;

    // payload

    //--- STATUS
    admin: boolean;
    status: boolean;

    constructor() {
        super();

        this._payload = [
            'admin',
            'status',
        ];

        this._query = [
            'target'
        ]
    }

    setStatusTarget() {
        this.target = 'STATUS'
    }

}
