import Http from "hays/http";
import ContentListInput from "modules/content/inputs/content-list.input";
import ContentUpdateInput from "modules/content/inputs/content-update.input";
import ContentVersionListInput from "modules/content/inputs/content-version-list.input";
const ContentApi = {};

ContentApi.list = async (inputs: ContentListInput) => {
    return Http.get('/contents', inputs.query())
};

ContentApi.versionList = async (inputs: ContentVersionListInput) => {
    return Http.get('/contents/versions', inputs.query())
};

ContentApi.update = async (inputs: ContentUpdateInput) => {
    return Http.put('/contents', inputs.payload())
};

export default ContentApi;
