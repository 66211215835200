import { useState, useEffect } from 'react';
import {useLocation} from "react-router-dom";
import qs from "qs";

export  function useQuery(defaults = {}) {
    const _defaults = { first_change: true, ... defaults};
    const location = useLocation();
    const [query, querySet] = useState(_defaults);

    useEffect(() => {
        let search = location.search;
        if (search.startsWith('?')) {
            search = search.substr(1);
        }
        querySet(qs.parse(search));
    }, [location.search]);

    return query;
}
