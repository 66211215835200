import React, { useState } from 'react';
import { Routes, Route, Link } from "react-router-dom";
import SettingsContainer from "modules/settings/containers/settings/settings.container";
import HomeContainer from "modules/dashboard/containers/home/home.container";
import Page404Container from "modules/dashboard/containers/page-404/page-404.container";
import UsersContainer from "modules/user/containers/users/users.container";
import TicketsContainer from "modules/contact/containers/tickets/tickets.container";
import ContentsContainer from "modules/content/containers/contents/contents.container";
import NotificationsContainer from "modules/notification/containers/notifications/notifications.container";

function DashboardRouterContainer() {

    return (
        <div className="dashboard-router-container">
            <Routes>
                <Route path="/" element={<HomeContainer />} />
                <Route path="settings" element={<SettingsContainer />} />
                <Route path="contents" element={<ContentsContainer />} />
                <Route path="notifications" element={<NotificationsContainer />} />
                <Route path="users" element={<UsersContainer />} />
                <Route exact path="/tickets" element={<TicketsContainer />} />
                <Route path="*" element={<Page404Container />} />
            </Routes>
        </div>
    );
}

export default DashboardRouterContainer;
