import React, {useEffect, useState} from 'react';
import  { getError } from "./errors";
import Switch from '@mui/material/Switch';
import {FormControlLabel} from "@mui/material";

export default function HrcSwitch(props) {

    const {
        form,
        name,
        id = name,
        label = false,
        onChange = (event) => {  },
        onBlur = (event) => {  },
        onFocus = (event) => {  },
        changeAsEvent = true,
        readOnly = false,
        ...reset
    }  = props;


    const handleChange = (event, value) => {
        if (readOnly) {
            return;
        }
        form.handleChange(name, value);
        onChange(event);
    };

    const handleBlur = (event) => {
        if (readOnly) {
            return;
        }
        onBlur(event);
        form.handleBlur(name);
    };

    const handleFocus = (event) => {
        if (readOnly) {
            return;
        }
        onFocus(event);
        form.handleFocus(name);
    };

    useEffect(() => {
        if (props.hasOwnProperty('value')) {
            throw "HrcSwitch didn't accept value prop directly, pass the value by form api";
        }
    }, [props.value]);

    useEffect(() => {
        if (!props.hasOwnProperty('name')) { throw "HrcSwitch: name required for useForm"; }
    }, [props.name]);


    return (
        <div className="form-group hrc-form-group-sm">

            <FormControlLabel
                control={
                    <Switch
                        name={name}
                        id={id}
                        checked={form.values[name]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        inputProps={{ 'aria-label': 'controlled' }}
                        { ...reset }
                    />
                }
                label={label}
            />
        </div>
    );
}
