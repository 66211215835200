//@flow
import HrcInput from "hays/types/Input";
export default class ContactListInput extends HrcInput{


    //query
    page: number;
    limit: number;
    status: string;
    follow_by: number;
    date_from: string;
    date_to: string;
    user_id: number;


    constructor() {
        super();
        this._query = [
            'page',
            'limit',
            'status',
            'follow_by',
            'date_from',
            'date_to',
            'user_id',
        ];
    }

}
