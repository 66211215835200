import React, {useEffect, useState} from 'react';
import "./contact-status.component.scss";
import {contactStatus} from "modules/contact/utils/contact.utils";

function ContactStatus(props) {

    const {
        status = null,
    } = props;

    const [localStatus, localStatusSet] = useState(null);


    useEffect(() => {
        localStatusSet(contactStatus[status] || {
            key: status,
            label: status,
            color: 'gray'
        })
    }, [status]);

    if (!status || !localStatus) {
        return <></>
    }

    return (
        <span className="contact-status-component" style={{color: localStatus.color}}>
            {localStatus.label}
        </span>
    );
}

export default ContactStatus;
