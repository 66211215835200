import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import {TextField} from "@mui/material";
import React from "react";
import AppConst from "modules/main/constants/app.const";


const DatePickerField = (props) => {

    const {
        form,
        name,
        onChange = (event) => {  },
        onBlur = (event) => {  },
        onFocus = (event) => {  },
        label = '',
        ...rest
    } = props;

    const handleChange = (value) => {
        form.handleChange(name, value);
        onChange();
    };

    const handleBlur = (event) => {
        onBlur(event);
        form.handleBlur(name);
    };

    const handleFocus = (event) => {
        onFocus(event);
        form.handleFocus(name);
    };


    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
                inputFormat={AppConst.DTAE_FORMAT}
                label={label}
                value={form.values[name]}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onChange={handleChange}
                renderInput={(params) => <TextField
                    {...params}
                    readOnly={true}  InputLabelProps={{ shrink: true, readOnly: true}}  fullWidth  />}
                {...rest}
            />
        </LocalizationProvider>
    );
}

export default DatePickerField;
