import React, {useEffect, useState} from 'react';
import Joi from "hays/joi";
import {FormTextField, useForm} from "hays/form";
import {Button, FormLabel} from "@mui/material";
import FileUploadModal, {FileUploadModalApi} from "modules/file-manager/modals/file-upload/file-upload.modal";
import FileManagerFolderConst from "modules/file-manager/constants/file-manager-folder.const";
import {AllFileUploadAllowedImagesTypes} from "modules/file-manager/components/file-upload/file-upload.component";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckIcon from "@mui/icons-material/Check";
import FormHelperText from '@mui/material/FormHelperText';
import NotificationSaveInput from "modules/notification/inputs/notification-save.input";
import NotificationApi from "modules/notification/apis/notification.api";
import _ from "lodash";
import DeleteIcon from '@mui/icons-material/Delete';
import NotificationDeleteInput from "modules/notification/inputs/notification-delete.input";
import SendIcon from '@mui/icons-material/Send';
import NotificationNotifyInput from "modules/notification/inputs/notification-notify.input";

const NotificationStoreActions = {
    CREATE: 'CREATE',
    EDIT: 'EDIT',
    NOTIFY: 'NOTIFY',
    DELETE: 'DELETE',
};
export { NotificationStoreActions as NotificationStoreActions }


const schema = {
    title_ar: Joi.string().required(),
    //link_ar: Joi.string().uri().required(),

    title_en: Joi.string().required(),
    //link_en: Joi.string().uri().required(),
};

const initialValues = {

    title_ar: '',
    body_ar: '',
    link_ar: '',
    image_ar_file: null,

    title_en: '',
    body_en: '',
    link_en: '',
    image_en_file: null,

    confirm_delete: '',
};

function NotificationStore(props) {

    const {
        onChange = () => {},
        notification = null,
        action = null,
    } = props;

    const form = useForm({initialValues, schema});

    const [loading, loadingSet] = useState(false);
    const [readOnly, readOnlySet] = useState(false);
    const [isNotify, isNotifySet] = useState(false);

    useEffect(() => {
        if (notification && action !== NotificationStoreActions.CREATE) {
            const values = _.pick(notification, [
                'title_ar',
                'body_ar',
                'link_ar',
                'image_ar_file',
                'title_en',
                'body_en',
                'link_en',
                'image_en_file',
            ]);

            if (values['image_ar_file']) {
                values['image_ar_file']['s3_url'] = notification.image_ar
            }

            if (values['image_en_file']) {
                values['image_en_file']['s3_url'] = notification.image_en
            }

            values['confirm_delete'] = '';

            Object.keys(values).forEach((key) => {
                if (key !== 'image_ar_file' &&  key !== 'image_en_file' && values[key] === null) {
                    values[key] = ''
                }
            });
            form.api.setFormValues(values);
        }

    }, [notification, action]);

    useEffect(() => {
        isNotifySet(action === NotificationStoreActions.NOTIFY );
        readOnlySet(action === NotificationStoreActions.NOTIFY || loading)
    }, [action, loading]);

    const onSubmitSuccess = ({values}) => {
        if (action !== NotificationStoreActions.NOTIFY) {
            save(values);
        }
    }

    const notify = () => {
        (async () => {
            try {
                loadingSet(true);
                const inputs = new NotificationNotifyInput();
                inputs.notification_id = notification.notification_id;
                const result = await NotificationApi.notify(inputs);
                onChange({
                    action: NotificationStoreActions.NOTIFY
                });
            } catch (e) {
                loadingSet(false);
                console.error(e);
            }
        })();
    };

    const deleteNotification = () => {
        (async () => {
            try {
                loadingSet(true);
                const inputs = new NotificationDeleteInput();
                inputs.notification_id = notification.notification_id;

                const result = await NotificationApi.delete(inputs);
                onChange({
                    action: NotificationStoreActions.DELETE
                });

            } catch (e) {
                loadingSet(false);
                console.error(e);
            }
        })();
    }

    const save = (values) => {
        (async () => {
            try {
                loadingSet(true);
                const inputs = new NotificationSaveInput();
                if (action === NotificationStoreActions.EDIT) {
                    inputs.notification_id = notification.notification_id;
                }
                inputs.title_ar = values.title_ar;
                inputs.title_en = values.title_en;
                inputs.body_ar = values.body_ar;
                inputs.body_en = values.body_en;
                inputs.link_ar = values.link_ar;
                inputs.link_en = values.link_en;
                inputs.image_id_ar = values?.image_ar_file?.file_id;
                inputs.image_id_en = values?.image_en_file?.file_id;
                const result = await NotificationApi.save(inputs);
                form.api.setFormDirty(false);
                onChange({
                    action: action
                });
            } catch (e) {
                loadingSet(false);
                console.error(e);
            }
        })();
    };

    const showUpload = (key) => {
        if (readOnly) {
            return;
        }
        form.handleFocus(key);
        FileUploadModalApi.show(true, {
            extra: {
                key: key
            }
        });
    };

    const onFileUploadModalChange = (payload) => {
        const key = payload.extra.key;
        setImageFile(key, payload.data.file);
    };

    const setImageFile = (key, value) => {
        form.api.setValue(key, value);
        form.api.setFormDirty(true);
    }

    if(!action || (action !== NotificationStoreActions.CREATE && !notification)) {
        return <></>
    }

    if (!form?.values) {
        return <></>
    }

    return (
        <div className="notification-store-component">

            <FileUploadModal
                onChange={onFileUploadModalChange}
                folder={FileManagerFolderConst.UPLOAD_IMAGES}
                allowedTypes={AllFileUploadAllowedImagesTypes} />

            <div className="row">
                <div className="col">
                    <span>Arabic</span>
                    <div>
                        <div className="space-xl"/>
                        <FormTextField
                            InputProps={{ readOnly: readOnly, }}
                            disabled={readOnly}
                            fullWidth
                            form={form}
                            name={'title_ar'}
                            label={'Title'}
                        />

                        <div className="space-xl"/>
                        <FormTextField
                            InputProps={{ readOnly: readOnly, }}
                            disabled={readOnly}
                            fullWidth
                            multiline={true}
                            minRows={3}
                            form={form}
                            name={'body_ar'}
                            label={'Body'}
                        />

                        <div className="space-xl"/>
                        <FormTextField
                            InputProps={{ readOnly: readOnly, }}
                            disabled={readOnly}
                            fullWidth
                            form={form}
                            name={'link_ar'}
                            label={'Link'}
                        />
                        <div className="space-xl"/>
                        <FormLabel >Image</FormLabel>
                        <div className="space-md"/>
                        <div className="d-flex justify-content-between align-items-center">
                            {
                                (!form?.values?.image_ar_file && !isNotify) &&
                                <Button color="secondary" onClick={() => showUpload('image_ar_file')}>Select Ar Image</Button>
                            }

                            {
                                (!!form?.values?.image_ar_file) &&
                                <a href={form?.values?.image_ar_file?.s3_url} target="_blank">
                                    <img style={{width: 60}} src={form?.values?.image_ar_file?.s3_url} alt=""/>
                                </a>
                            }


                            {
                                (!!form?.values?.image_ar_file && !isNotify) &&
                                <Button color="error" onClick={() => setImageFile('image_ar_file', null)}>Remove Ar Image</Button>
                            }
                        </div>
                    </div>
                </div>
                <div className="col">
                    <span>English</span>
                    <div>
                        <div className="space-xl"/>
                        <FormTextField
                            InputProps={{ readOnly: readOnly, }}
                            disabled={readOnly}
                            fullWidth
                            form={form}
                            name={'title_en'}
                            label={'Title'}
                        />

                        <div className="space-xl"/>
                        <FormTextField
                            InputProps={{ readOnly: readOnly, }}
                            disabled={readOnly}
                            fullWidth
                            multiline={true}
                            minRows={3}
                            form={form}
                            name={'body_en'}
                            label={'Body'}
                        />

                        <div className="space-xl"/>
                        <FormTextField
                            InputProps={{ readOnly: readOnly, }}
                            disabled={readOnly}
                            fullWidth
                            form={form}
                            name={'link_en'}
                            label={'Link'}
                        />
                        <div className="space-xl"/>
                        <FormLabel id="demo-row-radio-buttons-group-label">Image</FormLabel>
                        <div className="space-md"/>
                        <div className="d-flex justify-content-between align-items-center">
                            {
                                (!form?.values?.image_en_file && !isNotify) &&
                                <Button color="secondary" onClick={() => showUpload('image_en_file')}>Select En Image</Button>
                            }

                            {
                                (!!form?.values?.image_en_file) &&
                                <a href={form?.values?.image_en_file?.s3_url} target="_blank">
                                    <img style={{width: 60}} src={form?.values?.image_en_file?.s3_url} alt=""/>
                                </a>

                            }

                            {
                                (!!form?.values?.image_en_file && !isNotify) &&

                                <Button color="error" onClick={() => setImageFile('image_en_file', null)}>Remove En Image</Button>
                            }
                        </div>

                    </div>
                </div>
            </div>
            {
                (!isNotify) &&
                <>
                    <div className="space-md mb-2"/>
                    <div className="justify-content-center align-items-center d-flex">
                        <LoadingButton
                            variant="outlined"
                            fullWidth
                            loading={loading}
                            disabled={!form.dirty}
                            loadingPosition="start"
                            size={"large"}
                            startIcon={<CheckIcon />}
                            onClick={form.handleSubmit(onSubmitSuccess)}>
                            Save
                        </LoadingButton>
                    </div>
                </>
            }

            {
                (isNotify) &&
                <div>
                    <div className="space-xl mb-2"/>
                    <span>Notify</span>
                    <div className="space-xl "/>
                    <div className="row align-items-center">
                        <div className="col">
                            <FormTextField
                                fullWidth
                                form={form}
                                name={'confirm_delete'}
                                label={"Enter 'Confirm' to active button"}
                            />
                        </div>
                        <div className="col">
                            <LoadingButton
                                variant="outlined"
                                color="success"
                                fullWidth
                                loading={loading}
                                disabled={loading || form?.values?.confirm_delete?.toUpperCase() !== 'Confirm'.toUpperCase()}
                                loadingPosition="start"
                                size={"large"}
                                startIcon={<SendIcon />}
                                onClick={notify}>
                                Notify
                            </LoadingButton>
                        </div>
                    </div>
                </div>
            }


            {
                (action === NotificationStoreActions.EDIT) &&
                <div>
                    <div className="space-xl mb-2"/>
                    <span>Delete</span>
                    <div className="space-xl "/>
                    <div className="row align-items-center">
                        <div className="col">
                            <FormTextField
                                fullWidth
                                form={form}
                                name={'confirm_delete'}
                                label={"Enter 'Confirm' to active button"}
                            />
                        </div>
                        <div className="col">
                            <LoadingButton
                                variant="outlined"
                                color="error"
                                fullWidth
                                loading={loading}
                                disabled={loading || form?.values?.confirm_delete?.toUpperCase() !== 'Confirm'.toUpperCase()}
                                loadingPosition="start"
                                size={"large"}
                                startIcon={<DeleteIcon />}
                                onClick={deleteNotification}>
                                Delete
                            </LoadingButton>
                        </div>
                    </div>
                </div>
            }



        </div>
    );
}

export default NotificationStore;
