import React, { useState } from 'react';
import WidgetHomeGridForm from "modules/settings/components/widget-home-grid-form/widget-home-grid-form.component";
import {Button} from "@mui/material";
import WidgetUpdateInput from "modules/settings/inputs/widget-update.input";
import WidgetTypeConst from "modules/settings/constants/widget-type.const";
import WidgetApi from "modules/settings/apis/widget.api";
import _ from "lodash";
import {v4 as uuidv4} from "uuid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import CardContent from "@mui/material/CardContent";
import "./widget-home-grid.component.scss";

const Mode = {
    List: 'List',
    Add: 'Add'
}

function WidgetHomeGrid(props) {

    const {
        config = [],
        onChange = () => {},
    } = props;


    const [mode, modeSet] = useState(Mode.List);


    const listContent = (config || []).map((item) => {
        return (
            <div key={item.id} className={"item"}>
                <div className="row mb-1">
                    <div className="col-3">Delete</div>
                    <div className="col-9">
                        <Button color="error" onClick={() => deleteItem(item)}>Delete</Button>
                    </div>
                </div>
                <div className="row mb-1">
                    <div className="col-3">Link</div>
                    <div className="col-9">{item.link}</div>
                </div>
                <div className="row mb-1">
                    <div className="col-3">Image</div>
                    <div className="col-9"> <img src={item.file.s3_url} width="100" alt=""/></div>
                </div>
                <div className="row mb-1">
                    <div className="col-3">Sort</div>
                    <div className="col-9">{item.sort}</div>
                </div>
            </div>
        );
    });

    const save = (_config) => {
        (async () => {
            try {
                const inputs = new WidgetUpdateInput();
                inputs.type = WidgetTypeConst.HOME_GRID;
                inputs.config = _config;
                const result = await WidgetApi.update(inputs);
                onChange();
            } catch (e) {
                console.error(e);
            }
        })();
    };

    const onFormChange = (payload) => {
        let _config = _.cloneDeep(config);
        const item  =  payload.data;
        item.id = uuidv4();
        _config.push(item);
        save(_config);
    };

    const deleteItem = (item) => {
        const _config = config.filter((itm) => {
            return item.id !== itm.id
        });
        save(_config);
    }

    return (
        <div className="widget-home-grid-component">
            <Card>
                <CardHeader
                    title="Home Slider"
                    action={
                        <IconButton aria-label="settings" onClick={() => modeSet(Mode.Add)}>
                            <AddIcon />
                        </IconButton>
                    }
                />
                <CardContent>
                    {
                        mode === Mode.Add && <WidgetHomeGridForm  onChange={onFormChange}/>
                    }
                    {
                        mode === Mode.List &&
                        <div>
                            {listContent}
                        </div>
                    }

                </CardContent>
            </Card>
        </div>
    );
}

export default WidgetHomeGrid;
