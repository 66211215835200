
import React, {useEffect, useState} from 'react';
import Dialog from "@mui/material/Dialog";
import UserFiltersForm from "modules/user/components/user-filters-form/user-filters-form.component";
import {DialogContent, DialogTitle} from "@mui/material";
import UserEdit from "modules/user/components/user-edit/user-edit.component";

const UserEditModalApi = {
    show: () => {},
}
export { UserEditModalApi as UserEditModalApi };

function UserEditModal(props) {

    const {
        onChange = () => {},
        readOnly = false,
        ...rest
    }  = props;

    const [modalShow, setModalShow] = useState(false);
    const [user, userSet] = useState(null);
    const [userId, userIdSet] = useState(null);

    const show = (show = true, showOptions = {}) => {
        userSet(showOptions.user);
        userIdSet(showOptions.user_id);
        setModalShow(show);
    };

    useEffect(() => {
        UserEditModalApi.show = show;
    }, []);

    return (
        <Dialog fullWidth={true} maxWidth={'sm'} open={modalShow} onClose={() => setModalShow(false)} className="hays-md-modal"  {...rest} >

            {
                (!!modalShow) &&
                <>
                    <DialogTitle>
                        User
                    </DialogTitle>
                    <DialogContent>
                        <div className="space" />
                        <UserEdit readOnly={readOnly} user={user} userId={userId} onChange={onChange}/>
                    </DialogContent>
                </>
            }


        </Dialog>
    );

}

export default UserEditModal;
