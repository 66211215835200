import React, { useState, useEffect } from "react";
import {useForm, FormTextField} from "hays/form";
import Joi from "hays/joi";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from '@mui/lab/LoadingButton';
import CheckIcon from '@mui/icons-material/Check';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CityAddInput from "modules/settings/inputs/city-add.input";
import CityApi from "modules/settings/apis/city.api";
import CityUpdateInput from "modules/settings/inputs/city-update.input";
import Divider from '@mui/material/Divider';
import DeleteIcon from '@mui/icons-material/Delete';
import CityDeleteInput from "modules/settings/inputs/city-delete.input";

const CityAddFormMode = {
  ADD: 'ADD',
  UPDATE: 'UPDATE'
};
export { CityAddFormMode };

const schema = {
    name_ar: Joi.string().required(),
    name_en: Joi.string().required(),
};

const initialValues = {
    name_ar: '',
    name_en: '',
};

function CityAddForm(props) {

    const {
        mode = CityAddFormMode.ADD,
        parent = null,
        city = null,
        onChange = () => {},
    } = props;

    const form = useForm({initialValues, schema});

    const [loading, setLoading] = useState(false);
    const [confirmDelete, confirmDeleteSet] = useState('');
    const [parentLabel, parentLabelSet] = useState('Root');
    const [level, levelSet] = useState(1);
    const [ignoreSimilarNames, ignoreSimilarNamesSet] = useState(false);

    const onSubmitSuccess = ({values}) => {
        if (mode === CityAddFormMode.ADD) {
            createCity(values);
        }

        if (mode === CityAddFormMode.UPDATE) {
            updateCity(values);
        }

    };

    const updateCity = (values) => {
        (async () => {
            try {
                setLoading(true);
                const inputs = new CityUpdateInput();
                inputs.city_id = city.city_id;
                inputs.name_ar  = values.name_ar;
                inputs.name_en  = values.name_en;
                inputs.ignore_similar_names = ignoreSimilarNames;
                const result = await CityApi.update(inputs);
                setLoading(false);
                onChange();
            }
            catch (e) {
                console.error(e);
                if (e && e.validation) {
                    form.api.setErrorsByBE(e.validation);
                }
                setLoading(false);
            }
        })();
    };

    const createCity = (values) => {

        (async () => {
            try {
                setLoading(true);
                const inputs = new CityAddInput();
                inputs.ignore_similar_names = ignoreSimilarNames;
                inputs.name_ar  = values.name_ar;
                inputs.name_en  = values.name_en;
                inputs.parent_city_id = parent? parent.city_id : null;
                const result = await CityApi.create(inputs);
                setLoading(false);
                onChange();
            }
            catch (e) {

                console.error(e);
                if (e && e.validation) {
                    form.api.setErrorsByBE(e.validation);
                }
                setLoading(false);
            }
        })();
    };

    const deleteCity = () => {
        (async () => {
            try {
                setLoading(true);
                const inputs = new CityDeleteInput();
                inputs.city_id = city.city_id;
                const result = await CityApi.delete(inputs);
                setLoading(false);
                onChange();
            }
            catch (e) {
                console.error(e);
                if (e && e.validation) {
                    form.api.setErrorsByBE(e.validation);
                }
                setLoading(false);
            }
        })();
    };

    useEffect(() => {
        if (parent) {
            parentLabelSet(parent.name_en + ', ' +parent.name_ar);
            levelSet(parent.level + 1);
        }

        if (mode === CityAddFormMode.UPDATE && city) {
            form.api.setFormValues(city);
        }

    }, [mode, parent, city]);

    if (mode === CityAddFormMode.UPDATE && !city) {
        return <></>;
    }

    return (
        <div className="city-add-form">
            <DialogTitle>{(mode === CityAddFormMode.ADD ) ? 'New City' : 'Update City'}</DialogTitle>
            <DialogContent>
                <div className="modal-form">

                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                InputProps={{ readOnly: true, }}
                                label="Parent"
                                value={parentLabel}
                                InputLabelProps={{ shrink: true}}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                InputProps={{ readOnly: true, }}
                                label="City Level"
                                value={level}
                                InputLabelProps={{ shrink: true}}
                            />
                        </Grid>
                    </Grid>

                    <div className="space-xl"/>
                    <FormTextField
                        form={form}
                        name={'name_ar'}
                        label={'Name (Ar)'}
                    />

                    <div className="space-xl"/>
                    <FormTextField
                        form={form}
                        name={'name_en'}
                        label={'Name (En)'}
                    />

                    <div className="space-md"/>

                    <FormControlLabel
                        control={<Checkbox
                            value={ignoreSimilarNames}
                            onChange={(event) => ignoreSimilarNamesSet(event.target.checked)
                            }  />}
                        label="Ignore similar names" />

                </div>
                <DialogActions>
                    <LoadingButton
                        variant="outlined"
                        loading={loading}
                        loadingPosition="start"
                        startIcon={<CheckIcon />}
                        onClick={form.handleSubmit(onSubmitSuccess)}>
                        {(mode === CityAddFormMode.ADD ) ? 'Add' : 'Update'}
                    </LoadingButton>
                </DialogActions>
            </DialogContent>

            {
                (mode === CityAddFormMode.UPDATE) &&
                <>
                    <Divider />
                    <div className="space-md"/>
                    <DialogContent>
                        <TextField
                            fullWidth
                            autoComplete="false"
                            label="Enter 'Confirm Delete' to active delete button"
                            value={confirmDelete}
                            onChange={(event) => confirmDeleteSet(event.target.value)}
                            InputLabelProps={{ shrink: true}}
                        />
                        <div className="space-md"/>
                        <DialogActions>
                            <LoadingButton
                                variant="outlined"
                                color="error"
                                loading={loading}
                                disabled={confirmDelete.toUpperCase() !== 'Confirm Delete'.toUpperCase()}
                                loadingPosition="start"
                                startIcon={<DeleteIcon />}
                                onClick={deleteCity}>
                                Delete
                            </LoadingButton>
                        </DialogActions>
                    </DialogContent>
                </>
            }

        </div>

    );
}

export default CityAddForm;
